export default {
  route: {
    index: "Home",
    courseCenter: "Course Center",
    mySchedule: "My Schedule",
    monthSchedule: "Month Schedule",
    teacherArrange: "Teacher Arrangement",
    myCourse: "My Course",
    courseRecord: "Course Record",
    firstFeedback: "First Feedback",
    private: "1v1",
    class: "Class",
    detail: "Detail",
    trainingSpeaking: "Speaking",
    appointments: "Class List",
    plans: "Student List",
    schedule: "Schedule",
    analytic: "Summary",
    dashboard: "Control Panel",
    mark: "Reviews Statistics",
  },
  button: {
    search: "Search",
    reset: "Reset",
    submit: "Submit",
    cancel: "Cancel",
    saveAs: "Save",
    feedback: "Feedback",
    checkFeedback: "Check Feedback",
    batch_modification: "Batch Processing",
    prev: "prev",
    next: "next",
    firstPage: "1",
    logout: "Logout",
    resetPassword: "Change Password",
    intoClassroom: "classroom",
    checkPlayback: "Replay",
    checkMark: "Check Review",
    createPlayBack: "Replay Generating",
    haveDeleted: "Deleted",
  },
  columns: {
    feedback: "Feedback",
    passwordOld: "Old password",
    passwordNew: "New password",
    advice: "Advice",
    private: "1v1",
    class: "Class",
    band_score: "Band Score",
    booked_count: "Booked Count",
    basic_information: "Basic Information",

    category: "Category",
    created_at: "Create Time",
    criteria: "Criteria",
    class_type: "Class Type",
    completed_count: "completed Count",
    completed_course: "Complete Num",
    completed: "Completed",
    cancelled: "Cancelled",

    date: "Date",

    errorCorrection: "Error Correction",
    end_moment: "End Moment",

    is_active: "Activate",
    is_open: "Open",
    independent: "Independent",

    multiple: "Multiple",

    name: "English Name",
    username: "Username",
    night_shift_hours: "Night Shift Hours",
    next_exam_date: "Next Exam Date",

    operation: "Operation",

    placeholderSelect: "Select",
    placeholderInput: "Enter",
    previous_records: "Previous Records",

    remaining_count: "Remainder Lesson",
    reserved: "Booking",

    started_at: "Time",
    status: "Status",
    sub_status: "Substate",
    score: "Score",
    schedule: "Schedule",
    start_moment: "Time",
    student_absent: "Student Absent",
    student_name: "Name",

    time: "Time",
    type: "Type",
    teacher_absent: "Teacher Absent",

    working_hours: "Working Hours",
    wechat: "Wechat",
    welcome: "Welcome",
    yes: "Yes",
    no: "No",
    ielts: "IELTS",
    toefl: "TOEFL",

    pragmatic: "Practical English",
    mark: "Reviews Statistics", //评分统计
    number: "Number", //分数
    ava_score: "Average score",
    point: "Point",
    camera: "Camera",
    microphone: "Microphone",
    peaker: "Loudspeaker",
    start_etection: " Start Etection",
    detection_success: "Success",
    res_detection: "Redetect",
    detection: "Processing",
    device_test: "Device Detection",
    input_volume: "Input Volume",
    input_level: "Input Level",
    output_volume: "Output Volume",
    audio_play: "Play Audio",
    can_see: "Can See",
    can_not_see: "Can Not See",
    setting: "Setting",
    start_class: "Start Class",
    end_class: "End Class",
    audio: "Audio",
    screen_share: "Screen Share",
    stop_share: "Stop Share",
    switch_mode: "Switch Mode",
    whiteboard_mode: "Whiteboard",
    video_mode: "Video",
    fill_feedback: "Feedback",
    exit_classroom: "Exit Classroom",
    add_time: "Add Countdown",
    send: "Send",
    me: "Me",
    online: "Online",
    offline: "Offline",
    chat: "Chat",
    currently_online: "Currently online",
    speakers_list: "List of speakers",
    select_audio: "Select Audio",
    manage: "Manage",
    complete: "Complete",
    selected: "Selected",
    delete: "Delete",
    close: "Close",
    add_audio: "Add Audio",
    video_window: "Video Window",
    in_class: "In Class",
    up: "Upstream",
    down: "Downstream",
    countdown: "Countdown",
    minute: "Minute",
    second: "Second",
    affirm: "Affirm",
    select_courseware: "Select Courseware",
    courseware_manage: "Courseware Manage",
    cancel: "Cancel",
    modify_success: "Modify Success",
    total: "Total",
    section: "Section",
    course_arrangement: "Course Arrangemen",
    finished_section: "Finished Section",
    remaining_section: "Remaining Section",
    next_class_time: "Next Class Time",
    view_course: "View Course",
    no_course: "There are no classified courses yet~",
    course_canceled: "Course Canceled",
    live_ended: "Live Ended",
    replay_generating: "Replay Generating",
    to_be_class: "Waiting for class",
    watch_replay: "Replay",
    intoClassroom: "Into",
    hint: "Hint",
    notice_board: "Notice Board",
    confirm_publish: "Confirm Publish",
    course_living: "Living",
    page: "Page",
    current_online: "Current Online",
    admin: "Admin",
    no_one_speak: "No one has spoken yet",
    remove_speak: "Allow To Speak",
    all_ban_speak: "Ban To Speak",
    invite_speak: "Invite Speak",
    hang_up: "Hang Up",
    no_one_hand_up: "No One Hand Up",
    teacher: "Teacher",
    assistant: "Assistant",
    open_new_window: "The classroom will open in a new window",
    click_here: "Click Here",
    open: "Open",
    sound: "Sound",
    evaluate: "Review",
    people_evaluate: "evaluate",
    impression: "Impression",
    input_username: "Iput Usename",
    input_password: "Input Password",
    password_format_error: "Password Format Error",
    logining: "Logining",
    papa_live_teacher: "PAPA Live Teacher",
    papa_live_help_teacher: "PAPA Live Assistant",
    business_english: " Business English",
    year: "Year",
    month: "Month",
    unfold: "unfold",
    pack_up: "pack Up",
    day: "Day",
    startEndTime: 'time',
    remark: 'remark',
    Please_Enter_Content: 'Please enter content',
    Add_Teacher_Schedule: 'Add teacher schedule',
    show_courseware:'Show Courseware',
    hide_courseware:'Hide Courseware',

  },
  tips: {
    required: " is required",
    courseStatus: "Select Status",
    serverError: "The server error, place contact the manager",
    authorizationExpired:
      "The login information has expired. Please login again",
    pageNotFound: "The requested URL was not found on this server",
    noPermission:
      "You do not have permission to access this module, please contact the manager",
    allow_browser: "Please allow browser and webpage access",
    device_connecting: "The device is currently connected, please wait",
    device_connected:
      "The device is connected successfully. Please start device detection",
    can_see_tips: "Can you clearly see yourself?",
    can_see_volume_tips: "Can you see the volume icon bouncing?",
    can_hear_tips: "Can you hear the sound？",
    allow_camera:
      "You must authorize PAPA Education to access the camera in order to preview",
    microphone_tips:
      "Is there a problem with the microphone? Adjust the input volume and try saying anything funny",
    speaker_tips:
      "Please adjust the output volume and try playing the audio below",
    load_more: "Click to load more~",
    upload_local: "Upload Local",
    upload_tips:
      "Only files in MP3 format can be uploaded, and the size should not exceed 50MB",
    upload_courseware_tips:
      "Only files in doc/pdf/ppt format can be uploaded, and it should not exceed 10M",
    open_courseware: "Open Courseware",
    select_courseware_tips: "Please select the courseware and add it",
    last_page: "Last Page~",
    first_page: "First Page~",
    start_record: "Start Record",
    end_live: "Do you want to end the live broadcast",
    empty_msg: "Cannot send empty message",
    correct_time: "Please enter the correct time",
    not_class: "Not in class. Please start the countdown after starting class",
    not_class_time: "Not in class. Please start the share after starting class",
    audio_connect_error:
      "The audio connection on the student end is abnormal. Please exit the class together with the student and restart the class",

    video_connect_error:
      "The video input device you selected is abnormal. Please switch the input device and try again",
    not_end_class:
      "It's not time for class to finish yet. Please confirm if you want to exit the classroom",
    no_zhihu_live:
      "There is currently no information on Zhihu live broadcast room",
    not_support_live:
      "Your browser does not support live courses. It is recommended to download the latest version of Chrome browser",
    second_to_start: "The class will not start until  second",
    modify_group_info_success: "Successfully modified group information",
    say_to_students: "Say To Students",
    room_id_empty: "When sending group messages, the room number is empty",
    device_connect_success:
      "Device connection successful, please start device detection",
    play_audio_after_class: "Audio can only be played during class",
    file_uploading:
      "This file is currently being uploaded or has been successfully uploaded",
    file_too_large: "Upload file too large",
    file_format_error: "The format of the uploaded file is incorrect",
    file_upload_fail: "Upload failed",
    not_support_screen_share:
      "The current browser version does not support screen sharing function. Please replace the Chrome browser or upgrade to the latest version",
    local_video_error:
      "The local video transmission is abnormal. Please exit the class and restart the class",
    record_generate_fail:
      "Recording generation failed, please contact the assistant",
    no_course_today: "There is no class today",
    please_start_and_end : 'Please enter the start time and end time'
  },
};
