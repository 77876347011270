import * as Types from './mutationTypes.js'
import { SET_BG_AUDIO_STREAM, SET_SHAARE_STREAM } from './mutationTypes.js'

export default {
    [Types.SET_MAIN_CLIENT] (state, client) {
        state.mainClient = client
    },
    [Types.SET_WEB_RTC] (state, webRTC) {
        state.webRTC = webRTC
    },
    [Types.SET_TRTC_CLIENT] (state, trtcClient) {
        state.trtcClient = trtcClient
    },
    [Types.SET_LOCAL_STREAM] (state, localStream) {
        state.localStream = localStream
    },
    [Types.SET_IS_PUSHING] (state, status) {
        state.isPushing = status
    },
    [Types.SET_SHARE_CLIENT] (state, shareClient) {
        state.shareClient = shareClient
    },
    [Types.SET_SHAARE_STREAM] (state, shareStream) {
        state.shareStream = shareStream
    },
    [Types.SET_REMOTE_VIDEO_STREAM] (state, shareStream) {
        state.remoteVideoStream = shareStream
    },
    [Types.SET_VOLUME_VALUE] (state, value) {
        state.volumeValue = value
    },
    [Types.SET_IS_SUPPORT_SHARE] (state, status) {
        state.isSupportShare = status
    },
    [Types.SET_IS_VIDEO_CALL] (state, status) {
        state.isVideoCall = status
    },
    [Types.SET_BG_AUDIO_STREAM] (state, stream) {
        state.bgAudioStream = stream
    }
}
