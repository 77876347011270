import * as Types from './mutation-types'

export default {
  setCalendarData: ({commit}, data) => {
    commit(Types.SET_CALENDAR_DATA, data)
  },
  setTeacherAuction: ({commit}, data) => {
    commit(Types.SET_TEACHER_AUCTION, data)
  }
}
