import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../theme/index.css'
import i18n from './lang'
import ElementUI, { Table } from 'element-ui'
import animate from 'animate.css'
import echarts from 'echarts'
import '../src/assets/style/lib-reset.less'
import '@/assets/font/iconfont.css'
import VueCookies from 'vue-cookies'
import PapaTable from '@/components/PapaTable/PapaTable.vue'
import PapaForm from '@/components/PapaForm/PapaForm.vue'
import _ from 'loadsh'

import microApp from '@micro-zoe/micro-app'

microApp.start(
    {
      lifeCycles: {
        created (e, appName) {
          console.log(`子应用${appName}被创建`)
        },
        beforemount (e, appName) {
          console.log(`子应用${appName}即将渲染`)
        },
        mounted (e, appName) {
          console.log(`子应用${appName}已经渲染完成`)
        },
        unmount (e, appName) {
          console.log(`子应用${appName}已经卸载`)
        },
        error (e, appName) {
          console.log(`子应用${appName}加载出错`, e)
        }
      }
    }
)
// microApp.preFetch([
//   {name: 'mockRecord', level: 3, url: ''}
// ])
microApp.router.setBaseAppRouter(router)
//
import * as fundebug from 'fundebug-javascript'
import fundebugVue from 'fundebug-vue'

fundebug.init({
  apikey: 'd179b9b08fc1f1374cbcb70b76ad22808ad7345e43d7cded259f5169b631d674',
  appversion: '1.3.11', // todo 版本
  silentConsole: true,
})
fundebugVue(fundebug, Vue)
require('fundebug-revideo')
const fixElTableErr = ( table ) => {
  const oldResizeListener = table.methods.resizeListener
  table.methods.resizeListener = function () {
    window.requestAnimationFrame(oldResizeListener.bind(this))
  }
}
fixElTableErr(Table)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.use(animate)
Vue.component('PapaTable', PapaTable)
Vue.component('PapaForm', PapaForm)
Vue.prototype._ = _

new Vue({
  router,
  store,
  i18n,
  render: ( h ) => h(App),
}).$mount('#app')
