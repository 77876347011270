<script>
  export default {
    name: 'student',

    created () {
      if(process.env.NODE_ENV === 'development'){
        this.url = process.env.VUE_APP_VUE3_STUDENT
      }else{
        this.url = window.location.origin + '/app-student'
      }
    },
    data: () => {
      return {
        url: ''
      }
    },
  }
</script>

<template>
  <div class="module-student-wrapper">
    <micro-app name="student"
               :url="url+'/'"
               router-mode='native'
               baseroute="/app-student/student"
               keep-alive iframe></micro-app>
  </div>
</template>

<style scoped lang="less">
  .module-student-wrapper {
    width: 100%;
    height: 100vh;
  }
</style>
