import * as Types from "./mutationTypes.js";
import { SET_IS_SHOW_AUDIO_BAR } from "./mutationTypes.js";

export default {
  [Types.SET_TEDUBOARD](state, teduBoard) {
    state.teduBoard = teduBoard;
  },
  [Types.SET_FILE_LIST](state, fileList) {
    state.fileList = fileList;
  },
  [Types.SET_FILE_PAGINATION](state, filePagination) {
    state.filePagination = filePagination;
  },
  [Types.SET_CURRENT_FILE](state, file) {
    state.currentFile = file;
  },
  [Types.SET_FILEBOX_STATUS](state, status) {
    state.isShowFileListBox = status;
  },
  [Types.SET_CURRENT_BOARD_ID](state, boardId) {
    state.currentBoardId = boardId;
  },
  [Types.SET_UPLOAD_PERCENT](state, percent) {
    state.uploadPercent = percent;
  },
  [Types.SET_UPLOAD_FILE](state, file) {
    state.uploadFile = file;
  },
  [Types.SET_AUDIO_LIST](state, audioList) {
    state.audioList = audioList;
  },
  [Types.SET_AUDIO_PAGINATION](state, pagination) {
    state.audioPagination = pagination;
  },
  [Types.SET_CURRENT_AUDIO](state, audio) {
    state.currentAudio = audio;
  },
  [Types.SET_IS_SHOW_AUDIO_BAR](state, audioBarStatus) {
    state.isShowAudioBar = audioBarStatus;
  },
  [Types.SET_IS_PACK_UP_VIDEO](state, status) {
    state.isPackUpVideo = status;
  },
};
