import * as Types from "./mutationTypes";

export default {
  setTic: ({ commit }, tic) => {
    commit(Types.SET_TIC, tic);
  },
  setRoomID: ({ commit }, roomID) => {
    commit(Types.SET_ROOM_ID, roomID);
  },
  setLiveMode: ({ commit }, mode) => {
    commit(Types.SET_LIVE_MODE, mode);
  },

  addMsg: ({ commit }, msg) => {
    commit(Types.ADD_MSG, msg);
  },
  setUserList: ({ commit }, list) => {
    let currentTime = parseInt(new Date().getTime() / 1000);
    let listArr = [];
    list.forEach((ele) => {
      if (
        ele.userID.indexOf("tic_record") < 0 &&
        ele.userID.indexOf("desktop_share_record_user")
      ) {
        if (ele.muteUntil > currentTime) {
          ele.mute = true;
        }
        listArr.push(ele);
      }
    });
    commit(Types.SET_USER_LIST, listArr);
  },
  addUser: ({ commit }, user) => {
    commit(Types.ADD_USER, user);
  },
  setGroupInfo: ({ commit }, groupInfo) => {
    commit(Types.SET_GROUP_INFO, groupInfo);
  },
  setIsShowNotificationBox: ({ commit }, status) => {
    commit(Types.SET_NOTIFICATION_STATUS, status);
  },
  setUserHandStatus: ({ commit }, user) => {
    commit(Types.SET_USER_HAND_STATUS, user);
  },
  setUserConnectStatus: ({ commit }, user) => {
    commit(Types.SET_USER_CONNECT_STATUS, user);
  },
  setClassStatus: ({ commit }, status) => {
    commit(Types.SET_CLASS_STATUS, status);
  },
  setMicStatus: ({ commit }, status) => {
    commit(Types.SET_MIC_STATUS, status);
  },
  setVideoStatus: ({ commit }, status) => {
    commit(Types.SET_VIDEO_STATUS, status);
  },
  setVoiceStatus: ({ commit }, status) => {
    commit(Types.SET_VOICE_STATUS, status);
  },
  setShareStatus: ({ commit }, status) => {
    commit(Types.SET_SHARE_STATUS, status);
  },
  setVideoWrap: ({ commit }, wrap) => {
    commit(Types.SET_VIDEO_WRAP, wrap);
  },
  setCameraStatus: ({ commit }, status) => {
    commit(Types.SET_CAMERA_STATUS, status);
  },
  setUserMute: ({ commit }, user) => {
    commit(Types.SET_USER_MUTE, user);
  },
  setHandUpList: ({ commit }, list) => {
    console.log(list);
    commit(Types.SET_HAND_UP_LIST, list);
  },
  setConnectList: ({ commit }, list) => {
    commit(Types.SET_CONNECT_LIST, list);
  },
  setIsShowCourseWare: ({ commit }, status) => {
    commit(Types.SET_IS_SHOW_COURSE_WARE_STATUS, status);
  },
  setUserIMId: ({ commit }, userId) => {
    commit(Types.SET_USER_IM_ID, userId);
  },
  setUserIMToken: ({ commit }, token) => {
    commit(Types.SET_USER_IM_TOKEN, token);
  },
  setCanTeachStatus: ({ commit }, status) => {
    commit(Types.SET_CAN_TEACH_STATUS, status);
  },
  clearTic: ({ commit }) => {
    commit(Types.CLEAR_TIC);
  },
  setRecordTaskId: ({ commit }, id) => {
    commit(Types.SET_RECORD_TASK_ID, id);
  },
  setRecordInstance: ({ commit }, record) => {
    commit(Types.SET_RECORD_INSTANCE, record);
  },
  clearMsgList: ({ commit }) => {
    commit(Types.CLEAR_MSG_LIST);
  },
  addHistoryMsg: ({ commit }, list) => {
    commit(Types.ADD_HISTORY_MSG, list);
  },
  setCanBeginClass: ({ commit }, status) => {
    commit(Types.CAN_BEGIN_CLASS, status);
  },
  setUploadQuality: ({ commit }, quality) => {
    commit(Types.SET_UPLOAD_QUALITY, quality);
  },
  setDownloadQuality: ({ commit }, quality) => {
    commit(Types.SET_DOWNLOAD_QUALITY, quality);
  },
  setDownTime: ({ commit }, status) => {
    commit(Types.SET_DOWN_TIME, status);
  },
  setIsShowPackUp: ({ commit }, status) => {
    commit(Types.SET_IS_SHOW_PACK_UP, status);
  },
  setRemoteStreamVideo: ({ commit }, status) => {
    commit(Types.SET_REMOTE_STREAM_VIDEO, status);
  },
  setUserJionTim: ({ commit }, status) => {
    commit(Types.SET_UESR_JION_TIM, status);
  },
  setCameraDEviceId: ({ commit }, status) => {
    commit(Types.SET_CAMERA_DEVICE_ID, status);
  },
  setSpeakerDeviceId: ({ commit }, status) => {
    commit(Types.SET_SPEAKER_DEVICE_ID, status);
  },
  setMicDeviceId: ({ commit }, status) => {
    commit(Types.SET_MIC_DEVICE_ID, status);
  },
  setIsClassTime: ({ commit }, status) => {
    commit(Types.ISCLASSTIME, status);
  },
};
