<template>
  <div class="papa_table" :style="{ height: height }">
    <div class="table_filters" v-if="isSearchTable">
      <papa-form
        :form-column="search.formColumn"
        :form-data="search.formData"
        :form-config="search.config"
        @submitForm="submitForm"
        @searchForm="searchForm"
      ></papa-form>
    </div>
    <slot name="eachers"></slot>
    <div class="table_con" ref="tableConRef">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :max-height="tableHeight"
        size="mini"
      >
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <template v-for="item in tableColumn">
          <template v-if="item.type === 'timer'">
            <el-table-column
              :key="item.key"
              :prop="item.key"
              :label="generateColumns(item.label || item.key)"
              :style="item.style"
              :width="160"
            >
              <template v-slot="scope">
                <span>{{
                  formatMoment(
                    scope.row[item.key],
                    item.timerType || "YYYY-MM-DD HH:mm:ss"
                  )
                }}</span>
              </template>
            </el-table-column>
          </template>
          <template v-else-if="item.type === 'link'">
            <el-table-column
              :key="item.key"
              :prop="item.key"
              :label="generateColumns(item.label || item.key)"
              :style="item.style"
              :width="160"
            >
              <template v-slot="scope">
                <router-link
                  :to="{
                    name: item.linkName,
                    params: item.linkParams
                      ? handleLinkParams(item.linkParams, scope.row)
                      : { id: scope.row.id },
                  }"
                  >{{ readData(item.key, scope.row) }}
                </router-link>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            v-else
            :key="item.key"
            :prop="item.key"
            :label="generateColumns(item.label || item.key)"
            :style="item.style"
            :width="item.width"
          ></el-table-column>
        </template>

        <el-table-column
          v-if="tableConfig.buttons.length > 0"
          fixed="right"
          :label="generateColumns('operation')"
          :width="operationWidth"
        >
          <template slot-scope="scope">
            <template v-for="(button, index) in tableConfig.buttons">
              <el-button
                v-if="button === 'edit'"
                :key="index"
                type="primary"
                @click="handleEditTable(scope.row)"
                size="mini"
                >编辑
              </el-button>
              <el-button
                v-else-if="button === 'delete'"
                :key="index"
                type="danger"
                @click="handleDeleteTable(scope.row)"
                size="mini"
                >删除
              </el-button>
              <template
                v-else-if="button.key === 'custom' && scope.row[button.name]"
              >
                <div
                  v-if="button.type === 'text'"
                  :style="{
                    ...button.style,
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontWeight: '800',
                  }"
                  @click="handleCustomTable(scope.row, button.event)"
                  :key="index"
                >
                  {{ generateButton(button.name) }}
                </div>
                <el-button
                  v-else
                  :style="{ ...button.style }"
                  :key="index"
                  :type="button.type"
                  @click="handleCustomTable(scope.row, button.event)"
                  size="mini"
                >
                  {{ generateButton(button.name) }}
                </el-button>
              </template>
            </template>
            <div style="text-align: center" v-if="scope.row.noButton">~</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table_pagination" v-if="pagination.prev || pagination.next">
      <div class="pagination">
        <el-button size="mini" :disabled="prevButtonStatus" @click="goFirst">{{
          generateButton("firstPage")
        }}</el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="prevButtonStatus"
          @click="prevClick"
          icon="el-icon-arrow-left"
          >{{ generateButton("prev") }}</el-button
        >
        <button class="pagination_current_page">
          {{ pagination.current_page }}
        </button>
        <el-button
          type="primary"
          :disabled="nextButtonStatus"
          size="mini"
          @click="nextClick"
          >{{ generateButton("next")
          }}<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import PapaTableJs from "@/components/PapaTable/PapaTable.js";

export default PapaTableJs;
</script>

<style lang="less" scoped>
.spoken {
  width: 50%;
}
.table_pagination {
  margin-top: 15px;
  text-align: right;
}

.papa_table {
  height: calc(100vh - 104px);

  .table_filters {
    min-height: 46px;
  }

  .table_con {
    height: calc(100% - 100px);
  }

  .table_pagination {
    height: 47px;
    .pagination {
      display: flex;
      float: right;
      .pagination_current_page {
        margin: 0 10px;
        width: 2em;
      }
    }
  }
}
</style>
