import * as Types from './mutation-types'

export default {
  [Types.SET_CALENDAR_DATA] (state, data) {
    state.calendarData = data
  },
  [Types.SET_TEACHER_AUCTION] (state, data) {
    state.teacherAuction = data
  }
}
