<template>
  <div class="papa_form">
    <el-form
      size="mini"
      :model="dataFormData"
      :inline="showType === 'form' ? false : true"
      label-width="5em"
      ref="searchForm"
    >
      <template v-for="item in formColumn">
        <el-form-item
          v-if="item.type === 'input'"
          :key="item.key"
          :label="showType === 'form' ? generateColumns(item.key) : ''"
          :prop="item.key"
        >
          <form-input
            :form-value="dataFormData[item.key]"
            :key="item.key"
            :item="item"
            :prop="item.key"
            clearable
            style="width: 200px"
            :placeholder="
              generateColumns('placeholderInput') + generateColumns(item.key)
            "
            @setFormValue="setFormValue"
          ></form-input>
        </el-form-item>
        <el-form-item
          v-if="item.type === 'select'"
          :key="item.key"
          :label="showType === 'form' ? generateColumns(item.key) : ''"
          :prop="item.key"
        >
          <form-select
            :form-value="dataFormData[item.key]"
            :key="item.key"
            :item="item"
            :prop="item.key"
            clearable
            style="width: 200px"
            :placeholder="
              generateColumns('placeholderSelect') +
              generateColumns(item.showText || item.key)
            "
            @setFormValue="setFormValue"
          ></form-select>
        </el-form-item>
        <el-form-item
          v-if="item.type === 'switch'"
          :key="item.key"
          :label="showType === 'form' ? generateColumns(item.key) : ''"
          :prop="item.key"
        >
          <form-switch
            :form-value="dataFormData[item.key]"
            :key="item.key"
            :item="item"
            :prop="item.key"
            clearable
            style="width: 200px"
            @setFormValue="setFormValue"
          ></form-switch>
        </el-form-item>
        <el-form-item
          v-else-if="item.type === 'dateTimePicker'"
          :key="item.key"
          :label="showType === 'form' ? generateColumns(item.key) : ''"
          :prop="item.key"
        >
          <form-date-picker
            :form-value="dataFormData[item.key]"
            :key="item.key"
            :item="item"
            :placeholder="
              generateColumns('placeholderSelect') + generateColumns(item.key)
            "
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
            style="width: 325px"
            @setFormValue="setFormValue"
          ></form-date-picker>
        </el-form-item>
        <el-form-item
          v-else-if="item.type === 'datetimerange'"
          :key="item.key"
          :label="showType === 'form' ? generateColumns(item.key) : ''"
          :prop="item.key"
        >
          <form-date-picker
            :form-value="dataFormData[item.key]"
            :key="item.key"
            :item="item"
            type="datetimerange"
            :start-placeholder="
              generateColumns('placeholderSelect') +
              generateColumns(item.startKey)
            "
            :end-placeholder="
              generateColumns('placeholderSelect') +
              generateColumns(item.endKey)
            "
            value-format="yyyy-MM-dd HH:mm:ss"
            clearable
            style="width: 200px"
            @setFormValue="setFormValue"
          ></form-date-picker>
        </el-form-item>
        <el-form-item
          v-else-if="item.type === 'timeSelect'"
          :key="item.key"
          :label="showType === 'form' ? generateColumns(item.key) : ''"
          :prop="item.key"
        >
          <form-time-select
            :form-value="dataFormData[item.key]"
            :key="item.key"
            :item="item"
            :placeholder="
              generateColumns('placeholderSelect') + generateColumns(item.key)
            "
            clearable
            style="width: 200px"
            @setFormValue="setFormValue"
          ></form-time-select>
        </el-form-item>
        <el-form-item
          v-else-if="item.type === 'selectSelect'"
          :key="item.key"
          :prop="item.key"
        >
          <form-select-select
            :item="item"
            :key="item.key"
            :form-value="dataFormData[item.key]"
            @setFormValue="setFormValue"
          ></form-select-select>
        </el-form-item>
        <el-form-item
          v-else-if="item.type === 'cascader'"
          :key="item.key"
          :prop="item.key"
        >
          <form-cascader
            :item="item"
            :key="item.key"
            :form-value="dataFormData[item.key]"
            @setFormValue="setFormValue"
          ></form-cascader>
        </el-form-item>
      </template>
      <template>
        <el-form-item v-if="showType === 'search'">
          <el-button type="primary" @click="searchForm('searchForm')"
            >{{ generateButton("search") }}
          </el-button>
          <el-button @click="resetForm('searchForm')">{{
            generateButton("reset")
          }}</el-button>
          <el-button v-if="formConfig.customButton" @click="customForm">
            {{ generateButton(formConfig.customButton.name) }}
          </el-button>
        </el-form-item>
        <el-form-item v-else-if="showType === 'form'">
          <el-button type="primary" @click="submitForm('searchForm')"
            >{{ generateButton("submit") }}
          </el-button>
          <el-button @click="cancelForm">{{
            generateButton("cancel")
          }}</el-button>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import PapaForm from "@/components/PapaForm/PapaForm";

export default PapaForm;
</script>

<style scoped>
</style>
