<template>
  <el-collapse v-model="activeName" @change="handleChange" :accordion="accordion">
    <el-collapse-item v-for="item in collapseData" :title="item.name" :name="item.id" :key="item.id">
      <slot name="collapse" :collapseItemData="item">

      </slot>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
  import EleCollapseJs from './EleCollapse.js'

  export default EleCollapseJs
</script>

<style scoped>

</style>
