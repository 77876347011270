// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  const hasKey = this.$te("route." + title);

  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    const translatedTitle = this.$t("route." + title);

    return translatedTitle;
  }
  return title;
}

export function generatePage(page) {
  const translatedPage = this.$t("page." + page);
  return translatedPage;
}
export function generateButton(button) {
  const translatedButton = this.$t("button." + button);
  return translatedButton;
}
export function generateColumns(column, context) {
  if (context) {
    const translatedColumn = context.$t("columns." + column);
    return translatedColumn;
  } else {
    const translatedColumn = this.$t("columns." + column);
    return translatedColumn;
  }
}
export function generateTips(column) {
  const translatedColumn = this.$t("tips." + column);
  return translatedColumn;
}
