import * as Types from './moutationTypes.js'

export default {
    [Types.SET_IS_COLLAPSE] (state, status) {
        state.isCollapse = status
    },
    [Types.SET_PASSWORD_DIALOG] (state, status) {
        state.dialogChangePassword = status
    },
}
