export const USER_ROLE = {
  member: 200,
  admin: 300,
};
export const LIVE_MODE = {
  private: 1, // 1v1
  class: 2, // 班课
};
export const ENTERANCE = {
  speaking: 1, // 口语陪练进入直播间
  course: 2, // 课程进入直播间
};
// 群提示消息类型
export const WEB_IM_GROUP_TIP_TYPE = {
  JOIN: 1, //加入群组
  QUIT: 2, //退出群组
  KICK: 3, //被踢出群组
  SET_ADMIN: 4, //被设置为管理员
  CANCEL_ADMIN: 5, //被取消管理员
  MODIFY_GROUP_INFO: 6, //修改群资料
  MODIFY_MEMBER_INFO: 7, //修改群成员信息
};
//群提示消息-群资料变更类型
export const WEB_IM_GROUP_TIP_MODIFY_GROUP_INFO_TYPE = {
  FACE_URL: 1, //修改群头像 URL
  NAME: 2, //修改群名称
  OWNER: 3, //修改群主
  NOTIFICATION: 4, //修改群公告
  INTRODUCTION: 5, //修改群简介
};

// 当前用户状态
export const USER_STATUS = {
  STATUS_UNINIT: 0, // 未初始化
  STATUS_UNLOGIN: 1, // 未登录
  STATUS_LOGINED: 2, // 已登录
  STATUS_INCLASS: 3, // 在房间中
};

// 分辨率
export const RESOLUTION = {
  SD: "360p", // 标清
  HD: "720p", // 高清
  UHD: "1080p", // 超高清
};

// 课程状态

export const CLASS_STATUS = {
  NOT_START: 0, // 未开始
  STARTED: 1, // 上课中
  END: 2, // 已结束
};

export const JUDGE_ONLINE_TYPE = {
  APP: 1,
  WEB: 2
}
