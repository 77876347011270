export const MUTE_TIME = 86400;
export const LIVE_RECORD_COUNTDOWN = 3;
export const sdkAppId = process.env.VUE_APP_LIVE_SDK_APP_ID * 1;
export const TCPlayerAppId = process.env.VUE_APP_TCP_PLAYER_ID;
export const DECODEKEY = process.env.VUE_APP_DECRYPT_KEY;
export const UploadAudioLimit = 50; // 音频 最大 50M
export const UploadDocLimit = 50; // 课件 最大 10M
export const EMOJIBASEURL = "https://ncnd.papaen.com/assets/image/common/emoji/";
export const QINIU_UPLOAD_ADDRESS = [
  "//up.qiniup.com",
  "//up-z0.qiniup.com",
  "//upload.qiniup.com",
];
