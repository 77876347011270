export const SET_NEXT_MSG_SEQ = 'set_next_msg_seq'
export const SET_IS_COMPLETED = 'set_is_completed'
export const SET_TIM = 'set_tim'
export const SET_ISONLINE = 'isOnline'
export const SET_ISONLINEMESSAGENUM = 'isOnlineMessageNum'
export const SET_STUDENT_IN_CLASS_STATUS = 'studentInClassStatus'
export const SET_IS_IN_CLASS_ONLINE = 'isInClassOnline'
export const SET_STUDENT_PLATFORM = 'setStudentPlatform'
export const SET_CUSTOM_ONLINE_MESSAGE_STAMP= 'setCustomOnlineMessageStamp'
export const SET_STUDENT_ONLINE_STATUS = 'setStudentOnlineStatus'



