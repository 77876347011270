<template>
    <el-time-select
        v-bind="$attrs"
        v-model="dataValue"
        :picker-options="item.pickerOptions"
        @change="setValue"
    ></el-time-select>
</template>

<script>
import { formItemMixin } from '@/components/PapaForm/mixins/formItemMixin'

export default {
    mixins: [formItemMixin],
    name: 'formTimeSelect'
}
</script>

<style scoped>

</style>
