import * as types from './mutation-types'

export default {
  setUserInfo: ({commit}, userInfo) => {
    commit(types.SET_USER_INFO, userInfo)
  },
  setAuthorization: ({commit}, authorization) => {
    commit(types.SET_AUTHORIZATIONS, authorization)
  },
  setUserNickname: ({commit}, {nickname}) => {
    commit(types.SET_USER_NICKNAME, nickname)
  },
  setUserAvatar: ({commit}, {avatar}) => {
    commit(types.SET_USET_AVATAR, avatar)
  }
}
