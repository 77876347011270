<template>
  <div class="main__view">
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view :key="key"/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
  import Vue from 'vue'
  import PapaCollapse from '@/components/EleCollapse/index'
  import PapaDialog from '@/components/EleDialog/index'

  Vue.component('papa-collapse', PapaCollapse)
  Vue.component('papa-dialog', PapaDialog)

  export default {
    name: 'mainView',
    computed: {
      key () {
        return this.$route.path
      }
    },
    methods: {}
  }
</script>

<style lang="less" scoped>
  .main__view {
    position: relative;
  }

  /* fade-transform */
  .fade-transform-leave-active,
  .fade-transform-enter-active {
    transition: all .5s;
  }

  .fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
  }

  .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>
