<template>
    <el-cascader
        v-model="dataValue"
        :options="cascaderOptions"
        :props="{multiple: true}"
        @change="setFormValue"
        :placeholder="generateTips(item.placeholder)"
    ></el-cascader>
</template>

<script>
import {generateColumns, generateTips} from '@/utils/i18n'

export default {
    name: 'FormCascader',
    data () {
        return {
            dataValue: ''
        }
    },
    props: {
        formValue: {
            type: Array,
        },
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }

    },
    computed: {
        cascaderOptions () {
            let obj = _.cloneDeep(this.item.options)
            console.log(obj)
            if (this.dataValue&&this.dataValue.length>0 && this.dataValue[0]) {
                obj.forEach(option => {
                    if (option.value !== this.dataValue[0][0]) {
                        option.disabled = true
                    } else {
                        option.disabled = false
                    }
                })
            } else {
                obj.forEach(option => {
                    option.disabled = false
                })
            }
            return obj

        }
    },
    mounted () {
        this.dataValue = this.formValue
    },
    methods: {
        setFormValue (e) {
            if (e) {
                let obj = {...this.item}
                let status
                let sub_status = []
                e.forEach(ele=>{
                    status = ele[0]
                    if(ele[1]){
                        sub_status.push(ele[1])
                    }
                })
                obj.value = e
                obj.formatValue = {status, sub_status}
                this.$emit('setFormValue', obj)
            }
        },
        generateColumns,
        generateTips
    },
    watch: {
        formValue (newVal) {
            this.dataValue = newVal
        }
    }
}
</script>

<style scoped>

</style>
