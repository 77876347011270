import moment from "moment";
import { canEnterLiveRoom } from "@/api/live/live";
import * as Constant from "@/views/live/constants/constant";
import { generateColumns, generateTips } from "@/utils/i18n";
import * as Api from "@/api/live/live";

import { MessageBox } from "element-ui";

export default {
  generateColumns,
  findUser(user, list) {
    let userIndex = "";
    list.forEach((ele, index) => {
      if (ele.userID === user.userID) {
        userIndex = index;
      }
    });
    return userIndex;
  },

  //获取n位随机数
  random(n) {
    if (n > 21) return null;
    let re = new RegExp("(\\d{" + n + "})(\\.|$)");
    let num = (Array(n - 1).join(0) + Math.pow(10, n) * Math.random()).match(
      re
    )[1];
    return num;
  },

  formatMoment(time, type, timezone) {
    if (timezone) {
      // return moment(time * 1000).utcOffset(8).format(type)
    } else {
      return moment(time * 1000)
        .utcOffset(8)
        .format(type);
    }
  },
  openPage(url) {
    window.open(url, "_blank");
  },

  getLiveRoomStatus(id, liveMode, entrance) {
    return new Promise((resolve, reject) => {
      canEnterLiveRoom(
        id,
        liveMode,
        entrance,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },

  openLiveRoom(
    url,
    liveMode,
    id,
    entrance = Constant.ENTERANCE.course,
    context
  ) {
    this.getLiveRoomStatus(id, liveMode, entrance)
      .then(() => {
        MessageBox.alert(
          generateColumns("open_new_window", context),
          generateColumns("hint", context),
          {
            confirmButtonText: generateColumns("affirm", context),
          }
        )
          .then(() => {
            if (entrance == Constant.ENTERANCE.speaking) {
              Api.joinRoom(id, true,{ type: 1 });
              this.openPage(url);
            } else {
              // Api.joinRoom(id, false,{ type: 1 });
              this.openPage(url);
            }
          })
          .catch(() => {
            console.log("取消");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getFileType(file) {
    let filename = file;
    let index1 = filename.lastIndexOf(".") + 1;
    let index2 = filename.length;
    let type = filename.substring(index1, index2);
    return type;
  },

  getBrowserInfo() {
    var agent = navigator.userAgent.toLowerCase();
    var regStr_ie = /msie [\d.]+;/gi;
    var regStr_ff = /firefox\/[\d.]+/gi;
    var regStr_chrome = /chrome\/[\d.]+/gi;
    var regStr_saf = /safari\/[\d.]+/gi;
    var isIE = agent.indexOf("compatible") > -1 && agent.indexOf("msie" > -1); //判断是否IE<11浏览器
    var isEdge = agent.indexOf("edge") > -1 && !isIE; //判断是否IE的Edge浏览器
    var isIE11 = agent.indexOf("trident") > -1 && agent.indexOf("rv:11.0") > -1;
    if (isIE) {
      var reIE = new RegExp("msie (\\d+\\.\\d+);");
      reIE.test(agent);
      var fIEVersion = parseFloat(RegExp["$1"]);
      if (fIEVersion == 7) {
        return "IE/7";
      } else if (fIEVersion == 8) {
        return "IE/8";
      } else if (fIEVersion == 9) {
        return "IE/9";
      } else if (fIEVersion == 10) {
        return "IE/10";
      }
    } //isIE end
    if (isIE11) {
      return "IE/11";
    }
    //firefox
    if (agent.indexOf("firefox") > 0) {
      return agent.match(regStr_ff);
    }
    //Safari
    if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
      return agent.match(regStr_saf);
    }
    //Chrome
    if (agent.indexOf("chrome") > 0) {
      return agent.match(regStr_chrome);
    }
  },
  getBrower() {
    let browser = this.getBrowserInfo();
    let b_name = (browser + "").replace(/[0-9./]/gi, "");
    let b_version = parseInt((browser + "").replace(/[^0-9.]/gi, ""));
    return {
      name: b_name,
      version: b_version,
    };
  },
  throttle(fn, delay) {
    let timerId = null;
    let lastExecTime = 0;
    return function(...args) {
      const now = new Date().getTime();
      if (now - lastExecTime < delay) {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          lastExecTime = now;
          fn.apply(this, args);
        }, delay);
      } else {
        lastExecTime = now;
        fn.apply(this, args);
      }
    };
  },
  debounce(fn, delay) {
    let timerId = null;
    return function(...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  },
  isOverClassTime(time){
    //判断当前时间(十位时间戳)是否大于time 大于说明已经超过上课时间 返回true
    let now = new Date().getTime()/1000;
    if(now > time){
      return true
    }else{
      return false
    }
  },
  devicentsetMedia(windowHeight,dom){
    if(!dom)  return
    if(windowHeight<830){
      document.querySelector(dom).style.scale = "0.9"
      if(windowHeight<760){
        document.querySelector(dom).style.scale = "0.85"
        if(windowHeight<715){
        document.querySelector(dom).style.scale = "0.8"
        if(windowHeight<670){
          document.querySelector(dom).style.scale = "0.75"
          if(windowHeight<630){
            document.querySelector(dom).style.scale = "0.7"
            if(windowHeight<600){
              document.querySelector(dom).style.scale = "0.65"
            }
          }
        }
        }
      }
    }else{
      document.querySelector(dom).style.scale = "1"
    }
  }
};
