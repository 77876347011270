<template>
    <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group name="breadcrumb">
            <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
                <span v-if="item.redirect==='noRedirect'||index==levelList.length-1"
                      class="no-redirect">{{ generateTitle(item.meta.title) }}</span>
                <a v-else @click.prevent="handleLink(item)">{{ generateTitle(item.meta.title) }}</a>
            </el-breadcrumb-item>
        </transition-group>
    </el-breadcrumb>
</template>

<script>
import { compile } from 'path-to-regexp'
import { generateTitle } from '@/utils/i18n'

export default {
    data () {
        return {
            levelList: null
        }
    },
    watch: {
        $route (route) {
            // if you go to the redirect page, do not update the breadcrumbs
            if (route.path.startsWith('/redirect/')) {
                return
            }
            this.getBreadcrumb()
        }
    },
    created () {
        this.getBreadcrumb()
    },
    methods: {
        getBreadcrumb () {
            // only show routes with meta.title
            let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
            // const first = matched[0]

            // if (!this.isDashboard(first)) {
            //   matched = [{path: '/dashboard', meta: {title: 'Dashboard'}}].concat(matched)
            // }

            this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
        },
        isDashboard (route) {
            const name = route && route.name
            if (!name) {
                return false
            }
            return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
        },
        pathCompile (path) {
            // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
            const {params} = this.$route
            var toPath = compile(path)
            return toPath(params)
        },
        handleLink (item) {
            const {redirect, path} = item
            if (redirect) {
                this.$router.push(redirect)
                return
            }
            this.$router.push(this.pathCompile(path))
        },
        generateTitle
    },
}
</script>

<style lang="less" scoped>
.app-breadcrumb.el-breadcrumb {
    display: inline-block;
    font-size: 12px;
    color: #666;

    .no-redirect {
        color: #97a8be;
        cursor: text;
    }
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
    transition: all .5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
    opacity: 0;
    transform: translateX(20px);
}

.breadcrumb-move {
    transition: all .5s;
}

.breadcrumb-leave-active {
    position: absolute;
}
</style>
