import axios from "axios";
import store from "@/store";
import { Message } from "element-ui";
import router from "@/router";
import { getLanguage } from "@/lang";
import { generateTips } from "@/utils/i18n";
import * as Utils from "@/utils/utils";
axios.defaults.timeout = 5000;

axios.interceptors.request.use(
  config => {
    if (
      store.state.user.authorization.access_token &&
      config.url.indexOf("iclass.api.qcloud") < 0
    ) {
      config.headers.Authorization =
        store.state.user.authorization.token_type +
        " " +
        store.state.user.authorization.access_token;
      config.headers.lang = getLanguage();
    } else {
      config.headers = {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    switch (error.response.status) {
      case 400:
        Message.error(error.response.data.message);
        break;
      case 401:
        localStorage.clear();
        store.dispatch("setAuthorization", "");
        sessionStorage.targetUrl = window.location.href;
        Message.error(generateTips("authorizationExpired"));
        setTimeout(() => {
          // window.location.href = "/login";
        }, 500);
        break;
      case 403:
        console.log(error.response.data.message);
        Message.error(error.response.data.message);
        Message.error(generateTips("noPermission"));
        break;
      case 404:
        Message.error(generateTips("pageNotFound"));
        break;
      case 422:
        Message.error(error.response.data.message);
        break;
      case 423:
        Message.error(error.response.data.message);
        break;
      case 429:
        Message.error(error.response.data.message);
        break;
      case 500:
        Message.error(generateTips("serverError"));
        break;
      default:
        console.log(error.response);
        Message.error(
          generateTips("serverError") + "Code:" + error.response.status
        );
    }

    return Promise.reject(error.response.data);
  }
);

let fetch = function(url, method, data, fn, errorFn, type, version = "v1") {
  let options = {
    url: version + "/" + url,
    method: method
  };
  options[method === "get" ? "params" : "data"] = data;
  options.requestUser = type;
  if (localStorage.loginType === "assistant") {
    options.baseURL = process.env.VUE_APP_BASE_URL_ASSISTANT;
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_ASSISTANT;
  } else {
    options.baseURL = process.env.VUE_APP_BASE_URL_TEACHER;
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_TEACHER;
  }

 return axios(options)
    .then(response => {
      let messge = "";

     switch (response.status) {
        case 200:
          if (response.data.server_time) {
             fn && fn(response.data, response.data.meta);
          } else {
            return  fn &&
              fn(response.data.data, {
                ...response.data.meta,
                ...response.data.links
              });
          }
          break;
        case 201:
          if (response.data.data) {
            fn && fn(response.data.data);
          } else {
            fn && fn();
          }
          break;
        case 202:
          if (response.config.url.indexOf("exit_room") > -1) {
            if (Utils.default.isOverClassTime(store.state.live.isClassTime)) {
              messge =
                "请先下课 再退出哦～ 否则录播将延迟一小时生成 确认退出吗？";
            } else {
              //未到下课时间
              messge = "还未到下课时间，确认退出教室";
            }
            fn && fn(messge, response.status);
          } else {
            fn && fn();
          }
          break;
        case 204:
          if (response.config.url.indexOf("exit_room") > -1) {
            messge =
              "退出后课程将结束，您和学生都无法再次进入本教室，是否确认退出？";
            console.log(response, messge);
            fn && fn(messge, response.status);
          } else {
            fn && fn();
          }
          break;
        default:
      }
    })
    .catch(res => {
      errorFn && errorFn(res);
    });
};

export default fetch;
