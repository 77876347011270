import * as Types from './moutationTypes.js'

export default {
  setIsCollapse: ({commit}, isCollapse) => {
    commit(Types.SET_IS_COLLAPSE, isCollapse)
  },
    setPasswordDialog: ({commit},status) =>{
      commit(Types.SET_PASSWORD_DIALOG, status)
    }

}
