let fileList = [];
let filePagination = null;
let currentFile =
  (sessionStorage.currentFile && JSON.parse(sessionStorage.currentFile)) || "";
let isShowFileListBox = false;
let currentBoardId = "";
let uploadPercent = 0;
let uploadFile = null;
let teduBoard = null;
let audioList = [];
let audioPagination = null;
let isPackUpVideo = false;

export default {
  teduBoard: teduBoard,
  fileList: fileList,
  filePagination: filePagination,
  currentFile: currentFile,
  isShowFileListBox: isShowFileListBox,
  currentBoardId: currentBoardId,
  uploadPercent: uploadPercent,
  uploadFile: uploadFile,
  audioList: audioList,
  audioPagination: audioPagination,
  currentAudio: null,
  isShowAudioBar: false,
  isPackUpVideo,
};
