import Fetch from "@/utils/fetch";

const search = function(url, data, fn, errorFn, type) {
  return new Fetch(url + "/search", "get", data, fn, errorFn, type);
};
const serverTime = function(fn, errorFn, type) {
  return new Fetch("config/server_time", "get", "", fn, errorFn, type);
};

export { search, serverTime };
