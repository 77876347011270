import Vue from "vue";
import Vuex from "vuex";
import globalConfig from "./globalConfig";
import user from "./user";
import board from "./live/board";
import live from "./live/live";
import rtc from "./live/rtc";
import im from "./live/im";
import calendar from "./calendar";
import footer from "./live/footer";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    globalConfig,
    board,
    live,
    rtc,
    im,
    calendar,
    footer,
  },
});
