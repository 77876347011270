import Fetch from "@/utils/fetch";
import * as Constant from "@/views/live/constants/constant";

// 直播详情
/*
 * */
const getLiveDetail = function(id, data, liveMode, entrance, fn, errofn) {
  console.log(entrance);
  if (liveMode === Constant.LIVE_MODE.class) {
    return new Fetch(`course_schedules/${id}`, "get", data, fn, errofn);
  } else {
    if (entrance == Constant.ENTERANCE.speaking) {
      return new Fetch(
        `training/speaking_appointments/${id}`,
        "get",
        data,
        fn,
        errofn
      );
    } else {
      return new Fetch(`course_plan_tasks/${id}`, "get", data, fn, errofn);
    }
  }
};

// 班课 im 权限
const getPermission = function(id, liveMode, entrance, fn, errofn) {
  console.log(liveMode, entrance);
  if (liveMode === Constant.LIVE_MODE.class) {
    return new Fetch(
      `course_schedules/${id}/im_permission`,
      "post",
      "",
      fn,
      errofn
    );
  } else {
    if (entrance == Constant.ENTERANCE.speaking) {
      return new Fetch(
        `training/speaking_appointments/${id}/im_permission`,
        "post",
        "",
        fn,
        errofn
      );
    } else {
      return new Fetch(
        `course_plan_tasks/${id}/im_permission`,
        "post",
        "",
        fn,
        errofn
      );
    }
  }
};

// 教师文档
const teacherDocument = function(type, data, fn, errofn) {
  return new Fetch(`teacher_documents`, type, data, fn, errofn);
};

// 获取文档转码状态
const documentStatus = function(data, fn, errorfn) {
  return new Fetch(`teacher_documents/check`, "get", data, fn, errorfn);
};

const teacherDocumentUrl = function() {
  if (localStorage.loginType === "assistant") {
    return process.env.VUE_APP_BASE_URL_ASSISTANT + `teacher_documents`;
  } else {
    return process.env.VUE_APP_BASE_URL_TEACHER + `teacher_documents`;
  }
};

// 教师音频
const teacherAudio = function(type, data, fn, errofn) {
  return new Fetch(`teacher_audios`, type, data, fn, errofn);
};
// 获取音频状态

const audioStatus = function(type, data, fn, errorfn) {
  return new Fetch(`teacher_documents`, type, data, fn, errorfn);
};

const teacherAudioUrl = function() {
  if (localStorage.loginType === "assistant") {
    return process.env.VUE_APP_BASE_URL_ASSISTANT + `teacher_audios`;
  } else {
    return process.env.VUE_APP_BASE_URL_TEACHER + `teacher_audios`;
  }
};

// 全体禁言

const muteAllMember = function(data, id, fn, errorFn) {
  return new Fetch(
    `/course_schedules/${id}/shut_up_all_member`,
    "post",
    data,
    fn,
    errorFn
  );
};

// 录制相关
// 开始录制
const startRecord = function(id, liveMode, entrance, fn, errofn) {
  console.log(id, liveMode);
  if (liveMode === Constant.LIVE_MODE.class) {
    return new Fetch(
      `course_schedules/${id}/start_record`,
      "post",
      "",
      fn,
      errofn
    );
  } else {
    if (entrance == Constant.ENTERANCE.speaking) {
      return new Fetch(
        `training/speaking_appointments/${id}/start_record`,
        "post",
        "",
        fn,
        errofn
      );
    } else {
      return new Fetch(
        `course_plan_tasks/${id}/start_record`,
        "post",
        "",
        fn,
        errofn
      );
    }
  }
};

// 结束录制
const stopRecord = function(id, liveMode, entrance, fn, errofn) {
  console.log(entrance);
  if (liveMode === Constant.LIVE_MODE.class) {
    return new Fetch(
      `course_schedules/${id}/stop_record`,
      "post",
      "",
      fn,
      errofn
    );
  } else {
    if (entrance === Constant.ENTERANCE.speaking) {
      return new Fetch(
        `training/speaking_appointments/${id}/stop_record`,
        "post",
        "",
        fn,
        errofn
      );
    } else {
      return new Fetch(
        `course_plan_tasks/${id}/stop_record`,
        "post",
        "",
        fn,
        errofn
      );
    }
  }
};

// 是否可以进入教室
const canEnterLiveRoom = function(id, liveMode, entrance, fn, errofn) {
  console.log(id, liveMode, entrance);
  if (liveMode === Constant.LIVE_MODE.private) {
    if (entrance === Constant.ENTERANCE.speaking) {
      return new Fetch(
        `training/speaking_appointments/${id}/status`,
        "get",
        "",
        fn,
        errofn
      );
    } else {
      return new Fetch(`course_plan_tasks/${id}/status`, "get", "", fn, errofn);
    }
  } else {
    return new Fetch(`course_schedules/${id}/status`, "get", "", fn, errofn);
  }
};

// 点击退出房间按钮的提示

const exitClassRoomTip = (id, liveMode, entrance, fn, errofn) => {
  console.log(id, entrance);
  if (liveMode === Constant.LIVE_MODE.private) {
    if (entrance === Constant.ENTERANCE.speaking) {
      return new Fetch(
        `training/speaking_appointments/${id}/exit_room`,
        "post",
        "",
        fn,
        errofn
      );
    } else {
      return new Fetch(
        `course_plan_tasks/${id}/exit_room`,
        "get",
        "",
        fn,
        errofn
      );
    }
  } else {
    return new Fetch(`course_schedules/${id}/exit_room`, "get", "", fn, errofn);
  }
};

// 清空教室
const leaveRoom = function(id, liveMode, entrance, fn, errofn) {
  console.log(id, entrance);
  if (liveMode === Constant.LIVE_MODE.private) {
    if (entrance === Constant.ENTERANCE.speaking) {
      return new Fetch(
        `training/speaking_appointments/${id}/dismiss_room`,
        "post",
        "",
        fn,
        errofn
      );
    } else {
      return new Fetch(
        `course_plan_tasks/${id}/dismiss_room`,
        "post",
        "",
        fn,
        errofn
      );
    }
  } else {
    return new Fetch(
      `course_schedules/${id}/dismiss_room`,
      "post",
      "",
      fn,
      errofn
    );
  }
};

// 获取上传七牛token
const getQiniuToken = function(uploadType, data, fn, errofn) {
  if (uploadType === "file") {
    return new Fetch(`upload/file`, "get", data, fn, errofn);
  } else {
    return new Fetch(`upload/audio`, "get", data, fn, errofn);
  }
};

// 获取上传腾讯token
const getTencentToken = function(uploadType, data, fn, errofn) {
  if (uploadType === "file") {
    return new Fetch(`upload/tencent_cloud/file/token`, "get", data, fn, errofn);
  } else {
    return new Fetch(`upload/tencent_cloud/audio/token`, "get", data, fn, errofn);
  }
};


const joinRoom = function(id, entrance,type, fn, errofn) {
  return new Fetch(
    `training/speaking_appointments/${id}/join_room`,
    "post",
    type,
    fn,
    errofn
  );
  // if(entrance){
  // }else{
  //   return new Fetch(
  //     `course_plan_tasks/${id}/join_room`,
  //     "post",
  //     type,
  //     fn,
  //     errofn
  //   );
  // }
};
const changeMode = function(id, entrance,type, fn, errofn) {
  if(entrance === Constant.ENTERANCE.speaking){
    console.log('1v1');
    return new Fetch(
      `training/speaking_appointments/${id}/change_record_mode`,
      "post",
      type,
      fn,
      errofn
    );
  }else{
    return new Fetch(
      `course_plan_tasks/${id}/change_record_mode`,
      "post",
      type,
      fn,
      errofn
    );
  }
};
// /v1/course_plan_tasks/{course_plan_task_id}/change_record_mode
export {
  getLiveDetail,
  getPermission,
  teacherDocument,
  teacherAudio,
  startRecord,
  stopRecord,
  leaveRoom,
  teacherAudioUrl,
  teacherDocumentUrl,
  canEnterLiveRoom,
  exitClassRoomTip,
  audioStatus,
  documentStatus,
  getQiniuToken,
  muteAllMember,
  joinRoom,
  changeMode,
  getTencentToken
};
