<script>
  export default {
    name: 'mockRecord',
    created () {
      if(process.env.NODE_ENV === 'development'){
        this.url = process.env.VUE_APP_VUE3_MOCK_RECORD
      }else{
        this.url = window.location.origin + '/app-exam'
      }
      this.currentRoute = this.$route
    },
    data: () => {
      return{
        url: '',
        currentRoute: ''
      }
    }
  }
</script>

<template>
  <div class="mock-record-wrapper">
    <micro-app name="mockRecord"
               :url="url+'/'"
               :data="{currentRoute}"
               router-mode="native"
               keep-router-state
               baseroute="/app-exam/exam"
               keep-alive iframe ></micro-app>
  </div>
</template>

<style scoped lang="less">
  .mock-record-wrapper{
    width: 100%;
    height: 100vh;
  }
</style>

