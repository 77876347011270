import * as Types from './mutationTypes.js'

export default {
    setMainClient: ({commit}, client) => {
        commit(Types.SET_MAIN_CLIENT, client)
    },
    setWebRtc: ({commit}, webRTC) => {
        commit(Types.SET_WEB_RTC, webRTC)
    },
    setTrtcClient: ({commit}, trtcClient) => {
        commit(Types.SET_TRTC_CLIENT, trtcClient)
    },
    setLocalStream: ({commit}, localStream) => {
        commit(Types.SET_LOCAL_STREAM, localStream)
    },
    setIsPushIng: ({commit}, status) => {
        commit(Types.SET_IS_PUSHING, status)
    },
    setShareClient: ({commit}, shareClient) => {
        commit(Types.SET_SHARE_CLIENT, shareClient)
    },
    setShareStream: ({commit}, shareStream) => {
        commit(Types.SET_SHAARE_STREAM, shareStream)
    },
    setRemoteVideoStream: ({commit}, shareStream) => {
        commit(Types.SET_REMOTE_VIDEO_STREAM, shareStream)
    },
    setVolumeValue: ({commit}, value) => {
        commit(Types.SET_VOLUME_VALUE, value)
    },
    setIsSupportShare: ({commit}, status) => {
        commit(Types.SET_IS_SUPPORT_SHARE, status)
    },
    setIsVideoCall: ({commit}, status) => {
        commit(Types.SET_IS_VIDEO_CALL, status)
    },
    setBgAudioStream: ({commit}, stream) =>{
        commit(Types.SET_BG_AUDIO_STREAM, stream)
    }
}
