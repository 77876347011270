<template>
    <el-date-picker
        v-bind="$attrs"
        v-model="dataValue"
        @change="setValue"
        :value-format="item.valueFormat || 'yyyy-MM-dd hh:mm:ss'"

        :picker-options="item.pickerOptions"
    ></el-date-picker>
</template>

<script>
import { formItemMixin } from '@/components/PapaForm/mixins/formItemMixin'
export default {
    name: 'FormDatePicker',
    mixins: [formItemMixin],
}
</script>

<style scoped>

</style>
