import Layout from '@/layout/layout.vue'
import ExamRecord from '@/views/examRecord/examRecord.vue'


export default  [
  {
    path: "/exam",
    name: "exam",
    component: Layout,
    hidden: true,
    redirect: "/exam/record",
    meta: { title: '答题记录', icon: 'course1', role: 'personal-course' },
    children: [
      {
        path: '/exam/record',
        name: 'examRecord',
        component: ExamRecord,
        meta: { title: '列表', icon: 'course1', role: 'personal-course' },
      }
    ]
  }
]
