<template>
    <div class="selectSelect">
        <el-select slot="prefix" v-model="preSelectValue" style="width: 8em" @change="preChange"
                   clearable>
            <el-option
                v-for="option in item.selectOptions"
                :key="option.id"
                :label="option.value||option.name"
                :value="option.id || option.key"
            >
                {{ option.name || option.value }}
            </el-option>

        </el-select>
        <formSelect :item="selectItem" :form-value="dataValue" :placeholder="generateColumns(item.placeholder)"   @setFormValue="setFormValue"></formSelect>

    </div>
</template>

<script>
import formSelect from './FormSelect'
import { generateColumns } from '@/utils/i18n'

export default {
    name: 'FormSelectSelect',
    props: {
        formValue: {
            type: [String,Number],
            default: ''
        },
        item: {
            type: Object,
            default: () => {
                {
                }
            }
        },
        options: {
            get () {
                this.item.selectOptions.forEach(option => {
                    console.log(option)
                    option.name = this.generateColumns(option.name)
                })
                return this.item.selectOptions
            },
            set (option) {
                return option

            }

        }
    },
    data () {
        return {
            selectItem: {},
            preSelectValue: 'name',
            dataValue: ''
        }
    },
    mounted () {
        this.selectItem = this.item
        this.dataValue = this.formValue
    },
    methods: {
        remoteMethod () {

        },
        preChange(e){
            this.selectItem.searchKey = e
            console.log(e)
        },
        setValue (val) {
            let obj = {
                key: this.item.key,
                value: val
            }
            this.$emit('setFormValue', obj)
        },
        setFormValue(e){
            console.log(e)
            this.dataValue = e
            this.$emit('setFormValue',e)
        },
        generateColumns
    },
    watch: {
        formValue (newVal) {
            this.dataValue = newVal
        }
    },
    components: {
        formSelect
    }
}

</script>

<style scoped>

</style>
