<template>
    <el-select
        v-bind="$attrs"
        v-model="dataValue"
        :filterable="item.filterable"
        :remote="item.remote"
        @change="setValue"
        :remote-method="remoteMethod"
    >
        <el-option v-for="option in options" :key="option.key||option.id" :value="option.key||option.id"
                   :label="option.value||option.name">
            {{ option.value || option.name }}
        </el-option>
    </el-select>
</template>

<script>
import { generateColumns } from '@/utils/i18n'
import { formItemMixin } from '@/components/PapaForm/mixins/formItemMixin'
import { search } from '@/api/common/common'

export default {
    mixins: [formItemMixin],
    name: 'FormSelect',
    inheritAttrs: false,
    data () {
        return {
            optionList: []
        }
    },
    mounted () {
    },
    props: {
        formValue: [String, Number]
    },
    computed: {
        options: {
            get () {
                if (this.item.remote) {
                    return this.optionList
                } else {
                    if (this.item.isLocalOption) {
                        this.item.options.forEach(option => {
                            option.value = this.generateColumns(option.value)
                        })
                        return this.item.options
                    } else {
                        return this.item.options
                    }
                }

            },
            set (option) {
                return option

            }

        }
    },
    methods: {
        remoteMethod (query) {
            if (query.length < (this.item.minInput || 3)) return
            let params = {}
            params[this.item.searchKey] = query
            search(this.item.api, params, res => {
                this.optionList = res
            })
        },
        generateColumns
    },
}

</script>

<style scoped>

</style>
