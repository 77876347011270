export const SET_TIC = "set_tic";
export const SET_ROOM_ID = "set_roomID";
export const SET_LIVE_MODE = "set_live_mode";

export const ADD_MSG = "add_msg";
export const SET_USER_LIST = "set_user_list";
export const DEL_USER = "del_user";
export const ADD_USER = "add_user";
export const SET_GROUP_INFO = "set_group_info";
export const SET_NOTIFICATION_STATUS = "set_notification_status";
export const SET_USER_HAND_STATUS = "set_user_hand_status";
export const SET_USER_CONNECT_STATUS = "set_user_connect_status";
export const SET_CLASS_STATUS = "set_class_status";
export const SET_MIC_STATUS = "set_mic_status";
export const SET_VIDEO_STATUS = "set_video_status";
export const SET_VOICE_STATUS = "set_voice_status";
export const SET_SHARE_STATUS = "set_share_status";
export const SET_VIDEO_WRAP = "set_video_wrap";
export const SET_CAMERA_STATUS = "set_camera_status";
export const SET_USER_MUTE = "set_user_mute";
export const SET_CONNECT_LIST = "set_connect_list";
export const SET_HAND_UP_LIST = "set_hand_up_list";
export const SET_IS_SHOW_COURSE_WARE_STATUS = "set_is_show_course_ware";
export const SET_USER_IM_ID = "set_user_im_id";
export const SET_USER_IM_TOKEN = "set_user_im_token";
export const SET_CAN_TEACH_STATUS = "set_can_teach_status";
export const CLEAR_USER_LISST = "clear_user_list";
export const CLEAR_MSG_LIST = "clear_msg_list";
export const CLEAR_TIC = "clear_tic";
export const SET_RECORD_TASK_ID = "set_record_task_id";
export const SET_RECORD_INSTANCE = "set_record_instance";
export const ADD_HISTORY_MSG = "add_history_msg";
export const CAN_BEGIN_CLASS = "can_begin_class";
export const SET_UPLOAD_QUALITY = "set_upload_quality";
export const SET_DOWNLOAD_QUALITY = "set_download_quality";
export const SET_DOWN_TIME = "set_down_time";
export const SET_IS_SHOW_PACK_UP = "set_is_show_pack_up";
export const SET_REMOTE_STREAM_VIDEO = "set_remote_stream_video";
export const SET_UESR_JION_TIM = "set_user_jion_tim";
export const SET_CAMERA_DEVICE_ID = "set_camera_device_id";
export const SET_SPEAKER_DEVICE_ID = "set_speaker_device_id";
export const SET_MIC_DEVICE_ID = "set_mic_device_id";
export const ISCLASSTIME = "isClassTime"
