import { generateButton, generateColumns } from '@/utils/i18n'
import FormInput from '@/components/PapaForm/components/FormInput'
import FormSelect from '@/components/PapaForm/components/FormSelect'
import FormSelectSelect from '@/components/PapaForm/components/FormSelectSelect'
import FormDatePicker from '@/components/PapaForm/components/FormDatePicker'
import FormTimeSelect from '@/components/PapaForm/components/formTimeSelect'
import FormSwitch from '@/components/PapaForm/components/FormSwitch'
import FormCascader from '@/components/PapaForm/components/FormCascader'

export default {
    name: 'PapaForm',
    inheritAttrs: false,
    components: {
        FormSwitch,
        FormSelect,
        FormInput,
        FormDatePicker,
        FormTimeSelect,
        FormSelectSelect,
        FormCascader
    },
    data () {
        return {
            dataFormData: {}
        }
    },
    mounted () {
        this.dataFormData = _.cloneDeep(this.formData)
    },
    props: {
        formColumn: {
            type: Array,
            default: () => []
        },
        formData: {
            type: Object,
            default: () => {
            }

        },
        showType: {
            type: String,
            default: 'search'
        },
        formConfig: {
            type: Object,
            default: () => {
                return {
                    customButton: ''
                }
            }
        }
    },
    methods: {
        searchForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('searchForm', this.dataFormData)
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        submitForm () {
            this.$emit('submitForm', this.dataFormData)
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
            this.$emit('searchForm', {})
        },
        setFormValue (item) {
            if (item.formatValue) {
                this.dataFormData[item.key] = item.value
                this.dataFormData = {...this.dataFormData, ...item.formatValue}
            } else {
                this.dataFormData[item.key] = item.value
            }
            this.$emit('update:' + [item.key], item.value)
        },
        customForm () {
            console.log('form custom event')
        },
        cancelForm () {
            this.$emit('closeDialog')
        },
        generateButton,
        generateColumns
    },
    watch: {}
}
