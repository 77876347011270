export const SET_TEDUBOARD = "set_teduBoard";
export const SET_FILE_LIST = "set_file_list";
export const SET_FILE_PAGINATION = "set_file_list_pagination";
export const SET_CURRENT_FILE = "set_current_file";
export const SET_FILEBOX_STATUS = "set_file_box_status";
export const SET_CURRENT_BOARD_ID = "set_current_board_id";
export const SET_UPLOAD_PERCENT = "set_upload_percent";
export const SET_UPLOAD_FILE = "set_upload_file";
export const SET_AUDIO_LIST = "set_audio_list";
export const SET_AUDIO_PAGINATION = "set_audio_pagination";
export const SET_CURRENT_AUDIO = "set_current_audio";
export const SET_IS_SHOW_AUDIO_BAR = "set_is_show_audio_bar";
export const SET_IS_PACK_UP_VIDEO = "set_is_pack_up_video";
