<template>
  <div id="app">
    <router-view :key="$route.name" />
    <el-dialog
      :title="generateButton('resetPassword')"
      :visible.sync="dialogChangePassword"
      @close="closeDialog"
      width="380px"
    >
      <el-form
        size="mini"
        label-width="120px"
        ref="changePassword"
        :model="changePasswordForm"
      >
        <el-form-item
          :label="generateColumns('passwordOld')"
          prop="password"
          :rules="[{ required: true, trigger: ['change', 'blur'] }]"
        >
          <el-input v-model="changePasswordForm.password"></el-input>
        </el-form-item>
        <el-form-item
          :label="generateColumns('passwordNew')"
          prop="new_password"
          :rules="[{ required: true, trigger: ['change', 'blur'] }]"
        >
          <el-input v-model="changePasswordForm.new_password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" size="mini" @click="handleChangePassword">{{
          generateButton("submit")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as Api from "@/api/login/login.js";
import { mapActions } from "vuex";
import { generateButton, generateColumns, generateTips } from "@/utils/i18n";
import { configLive } from "@/api/course/course";
import AES from "@/utils/AES";
let Base64 = require("js-base64").Base64;
import * as Config from "@/config/live/live";
export default {
  beforeCreate() {
    if (!localStorage.authorization) {
      sessionStorage.targetUrl = window.location.href;
      setTimeout(()=>{
        this.$router.push({
          path: "/login",
        });
      },500)

    }
  },
  data() {
    return {
      changePasswordForm: {
        password: "",
        new_password: "",
      },
    };
  },
  computed: {
    dialogChangePassword: {
      get() {
        return this.$store.state.globalConfig.dialogChangePassword;
      },
      set(value) {
        this.setPasswordDialog(value);
      },
    },
  },
  mounted() {
    if (this.$cookies.isKey("userSign")) {
      if (localStorage.authorization) {
        this.getAppId();
        this.$cookies.set('teacherAuthorization', localStorage.authorization, "20d");

      }
      sessionStorage.userSign = this.$cookies.get("userSign");
    } else {
      if (localStorage.authorization) {
        Api.getIMToken((res) => {
          this.setUserIMToken(res.user_sign);
          this.$cookies.set("userSign", res.user_sign, "20d");
          sessionStorage.userSign = this.$cookies.get("userSign");
          this.getAppId();
        });
      }
    }
  },
  methods: {
    handleChangePassword() {
      this.$refs.changePassword.validate((valid) => {
        if (valid) {
          let params = {
            ...this.changePasswordForm,
          };
          Api.changePassword(params, () => {
            this.closeDialog();
            this.$message.success(this.generateColumns("modify_success"));
          });
        } else {
          return false;
        }
      });
    },
    closeDialog() {
      this.setPasswordDialog(false);
    },
    async getAppId() {
      await configLive(
        {},
        (res) => {
          const { iv, value } = JSON.parse(Base64.decode(res.encrypt));
          const config = JSON.parse(
            AES.decrypt(value, Config.DECODEKEY, "CBC", iv)
          );
          localStorage.setItem(
            "SDKAppId",
            JSON.stringify(config.tencent_cloud.trtc.sdk_app_id)
          );
          localStorage.setItem(
            "SUBAppid",
            JSON.stringify(config.tencent_cloud.vod.sub_app_id)
          );
        },
        () => {
          localStorage.setItem("SDKAppId", JSON.stringify(Config.sdkAppId));
          localStorage.setItem(
            "SUBAppid",
            JSON.stringify(Config.TCPlayerAppId)
          );
        }
      );
    },

    generateColumns,
    generateButton,
    generateTips,
    ...mapActions(["setUserIMToken", "setPasswordDialog"]),
  },
};
</script>
<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
