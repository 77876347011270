export default {
  name: 'papa-collapse',
  data () {
    return {
      activeName: '',
    }
  },
  props: {
    accordion: {
      type: Boolean,
      default: true
    },
    collapseData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    handleChange (activePanel) {
      this.$emit('collapseChange', {activePanel: activePanel})
    }
  }
}
