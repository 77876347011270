export const formItemMixin = {
    props:{
        formValue: {
            type: [String,Number,Array],
            default: ''
        },
        item: {
            type: Object,
            default: ()=>{{}}
        },
    },
    data(){
        return{
            dataValue: ''
        }
    },
    mounted () {
        this.dataValue = this.formValue
    },
    methods: {
        setValue(val){
            console.log(val)
            let obj = {
                key: this.item.key,
                value: val
            }
            console.log(obj);
            this.$emit('setFormValue',obj)
        }
    },
    watch: {
        formValue(newVal){
            this.dataValue = newVal
        }
    }
}
