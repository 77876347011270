<template>
  <div class="calendar">
    <div class="calendar-con">
      <div class="calendar-main">
        <div class="content">
          <div class="week">
            <div class="week-item" v-for="item in table.week" :key="item">
              {{ item }}
            </div>
          </div>
          <div class="day-box" v-show="viewDays.length > 0">
            <div
              class="row-group"
              style="border: none"
              v-show="calendarStatus === 1"
            >
              <div
                class="day"
                v-for="day in smallViewData"
                :key="day.date"
                :data-date="day.date"
                @click="dClick(day)"
              >
                <div
                  v-if="day.class === 'after' || day.class === 'before'"
                  class="con"
                >
                  <div class="day-con" :class="day.class">{{ day.show }}</div>
                </div>
                <div v-else class="con" @click="chooseDay(day)">
                  <div
                    class="day-con"
                    :class="currentChooseDate == day.date ? 'active' : ''"
                  >
                    {{ day.show }}
                  </div>
                  <div
                    v-if="day.data.length > 0 && currentChooseDate !== day.date"
                    class="point"
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="row-group"
              v-for="(week, index) in viewDays"
              :key="index"
              v-show="calendarStatus === 0"
            >
              <div
                class="day"
                v-for="day in week"
                :key="day.date"
                :data-date="day.date"
                @click="dClick(day)"
              >
                <div
                  v-if="day.class === 'after' || day.class === 'before'"
                  class="con"
                >
                  <div class="day-con" :class="day.class">{{ day.show }}</div>
                </div>
                <div v-else class="con" @click="chooseDay(day)">
                  <div
                    class="day-con"
                    :class="currentChooseDate == day.date ? 'active' : ''"
                  >
                    {{ day.show }}
                  </div>
                  <div
                    v-if="day.data.length > 0 && currentChooseDate !== day.date"
                    class="point"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="month-view" v-if="calendarStatus === 0">
        <div class="top">
          <div class="last-month">
            <i class="iconfont papa-icon-rt" @click="changeMonthView(-1)"></i>
          </div>
          <div class="current-month">
            {{ currentChooseYear }}{{ generateColumns("year")
            }}{{ currentChooseMonth }}{{ generateColumns("month") }}
          </div>
          <div class="next-month">
            <i class="iconfont papa-icon-right" @click="changeMonthView(1)"></i>
          </div>
        </div>
        <div class="current-day">{{ currentChooseDay }}日</div>
        <div class="day-week">{{ currentChooseDayInWeek }}</div>
        <div class="action">
          <div
            class="hide iconfont papa-icon-sanjiaoshang"
            @click="handleCalendarStatus(1)"
          >
            {{ generateColumns("pack_up") }}
          </div>
        </div>
      </div>
      <div class="month-view collapse" v-else>
        <div class="top">
          <div class="last-month">
            <i class="iconfont papa-icon-rt" @click="changeMonthView(-1)"></i>
          </div>
          <div class="current-month">
            {{ currentChooseYear }}{{ generateColumns("year")
            }}{{ currentChooseMonth }}{{ generateColumns("month") }}
          </div>
          <div class="next-month">
            <i class="iconfont papa-icon-right" @click="changeMonthView(1)"></i>
          </div>
        </div>
        <div class="current-day">
          <div>{{ currentChooseDay }}{{ generateColumns("day") }}</div>
          <div class="day-week">{{ currentChooseDayInWeek }}</div>
        </div>
        <div class="action">
          <div
            class="show iconfont papa-icon-sanjiao_xia"
            @click="handleCalendarStatus(0)"
          >
            {{ generateColumns("unfold") }}
          </div>
        </div>
      </div>
    </div>
    <div class="course-list-box">
      <div class="list" v-if="currentDayCourse && currentDayCourse.length > 0">
        <div class="item" v-for="course in currentDayCourse" :key="course.id">
          <div class="course-title" v-if="course.user || course.user === ''">
            <div class="title">
              <span class="course_name">{{
                course.user || course.user === ""
              }}</span>
              <span class="course_tag">
                {{ generateColumns("private") }}
              </span>
            </div>
            <div class="desc">
              {{ course.category }}|{{ course.course_subject }}
            </div>
          </div>
          <div class="course-title" v-else>
            <div class="title">
              <span class="course_name">{{ course.course.name }}</span>
              <span class="course_tag"> {{ generateColumns("class") }} </span>
            </div>
            <div class="desc">{{ course.name }}</div>
          </div>
          <div class="course-time">
            <div class="course-date">
              {{ formateMoment(course.started_at, "YYYY/MM/DD") }}
            </div>
            <div class="date-time">
              {{ formateMoment(course.started_at, "HH:mm") }}-{{
                formateMoment(course.ended_at, "HH:mm")
              }}
            </div>
          </div>
          <template v-if="course.user || course.user === ''">
            <div class="course-status" v-if="course.status === 3">
              {{ generateColumns("course_canceled") }}
            </div>
            <div
              class="course-status status_gray"
              v-if="course.status === 2 && course.is_recorded"
            >
              {{ generateColumns("live_ended") }}
            </div>
            <template v-if="course.status === 2 && !course.is_recorded">
              <div
                class="course-status status_gray"
                v-if="course.is_online_course"
              >
                {{ generateColumns("replay_generating") }}
              </div>
              <div class="course-status status_gray" v-else>
                {{ generateColumns("completed") }}
              </div>
            </template>
            <div class="course-status" v-if="course.status === 1">
              {{ generateColumns("to_be_class") }}
            </div>
          </template>
          <div class="course-status" v-else></div>

          <template v-if="course.user || course.user === ''">
            <div
              class="button"
              v-if="course.status === 3 && course.is_recorded"
              @click="Util.openPage(`/course/private/replay/${course.id}`)"
            >
              {{ generateColumns("watch_replay") }}
            </div>
            <div
              class="button button--disable"
              v-if="course.status === 3 && !course.is_recorded"
            >
              {{ generateColumns("watch_replay") }}
            </div>
            <div
              class="button"
              v-if="course.status === 2 && course.is_recorded"
              @click="Util.openPage(`/course/private/replay/${course.id}`)"
            >
              {{ generateColumns("watch_replay") }}
            </div>
            <div
              class="button button--disable"
              v-if="course.status === 2 && !course.is_recorded"
            >
              {{ generateColumns("watch_replay") }}
            </div>
            <div
              class="button"
              v-if="course.status === 1"
              @click="openLiveRoom(course)"

            >
              {{ generateColumns("intoClassroom") }}
            </div>
          </template>
          <template v-else>
            <!--            <div class="button" v-if="course.status === 3&&course.is_recorded" @click="Util.openPage(`/course/replay/${course.id}`)">观看回放-->
            <!--            </div>-->
            <!--            <div class="button button&#45;&#45;disable" v-if="course.status === 3&&course.is_recorded" >观看回放-->
            <!--            </div>-->
            <!--            <div class="button button&#45;&#45;disable" v-if="course.status === 2&&!course.is_recorded" >观看回放-->
            <!--            </div>-->
            <!--            <div class="button" v-if="course.status === 2&&course.is_recorded"-->
            <!--                 @click="Util.openPage(`/course/replay/${course.id}`)">观看回放-->
            <!--            </div>-->
            <div
              class="button"
              v-if="course.started_at > todayZero"
              @click="getClassDetail(course.id)"
            >
              {{ generateColumns("intoClassroom") }}
            </div>
            <div class="button button-disabled" v-else>
              {{ generateColumns("intoClassroom") }}
            </div>
          </template>
        </div>
      </div>
      <div class="no-course" v-else>{{ generateTips("no_course_today") }}</div>
    </div>
  </div>
</template>

<script>
import calendarJs from "./schedule.js";

export default calendarJs;
</script>

<style lang="less" scoped>
.calendar {
  width: 100%;

  & > .calendar-con {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > .calendar-main {
      width: 66%;

      & > .content {
        width: 100%;
        /*height: 286px;*/
        box-sizing: border-box;
        display: table;
        border-collapse: collapse;
        border: 1px solid #e6e6e6;
        font-size: 14px;
        color: #333;

        & > .week {
          width: 100%;
          height: 38px;
          display: table;
          border-bottom: none;
          box-sizing: border-box;
          border-bottom: 1px solid #e6e6e6;

          & > .week-item {
            display: table-cell;
            line-height: 38px;
            text-align: center;
          }
        }

        & > .day-box {
          width: 100%;
          display: table;
          border-collapse: collapse;

          & > .row-group:last-child {
            border-bottom: none;
          }

          & > .row-group {
            display: table-row-group;
            border-bottom: 1px solid #e6e6e6;

            & > .day {
              box-sizing: border-box;
              /*border: 1px solid #E6E6E6;*/
              /*width: 127px;*/
              height: 50px;
              line-height: 50px;
              display: table-cell;
              text-align: center;

              & > .con {
                height: 50px;
                overflow-y: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;

                & > .day-con {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  text-align: center;
                  line-height: 30px;
                  cursor: pointer;
                }

                & > .point {
                  position: absolute;
                  bottom: 10px;
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background: red;
                }

                & > .active {
                  background: #29d087;
                  color: #fff;
                }

                .after,
                .before {
                  color: #8c939d;
                  cursor: not-allowed;
                }

                & > .list {
                  font-size: 12px;
                  white-space: nowrap;
                  width: 105px;
                  overflow-x: hidden;
                  cursor: pointer;
                }

                & > .green {
                  color: #309525;
                  background: #f4fdf8;
                }

                & > .blue {
                  color: #225895;
                  background: #f4fafd;
                }
              }
            }
          }
        }
      }
    }

    & > .month-view {
      width: 30%;
      border: 1px solid #e6e6e6;
      position: relative;

      & > .top {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 35px;

        & > .current-month {
          /*margin: 0 10px;*/
        }

        .iconfont {
          font-size: 12px;
          cursor: pointer;
          padding: 0 10px;
        }
      }

      & > .current-day {
        margin-top: 60px;
        font-size: 32px;
        font-weight: 500;
        color: #333;
        text-align: center;
      }

      & > .day-week {
        margin-top: 20px;
        color: #666666;
        text-align: center;
        font-size: 14px;
      }

      & > .action {
        width: 58px;
        height: 24px;
        background: #e6e6e6;
        text-align: center;
        line-height: 24px;
        position: absolute;
        color: #999;
        right: 0;
        bottom: 0;
        cursor: pointer;

        & > div {
          font-size: 12px;
        }
      }
    }

    & > .collapse {
      & > .top {
        margin-top: 10px;
      }

      & > .current-day {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > .day-week {
          font-size: 14px;
          color: #666666;
          margin-left: 5px;
        }
      }
    }
  }

  & > .course-list-box {
    border: 1px solid #e6e6e6;
    margin-top: 30px;
    border-radius: 4px;
    padding: 0 30px;

    & > .list {
      & > .item:last-child {
        border-bottom: none;
      }

      & > .item {
        width: 100%;
        height: 108px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;
        justify-content: space-between;

        & > .course-title {
          max-width: 40%;
          color: #555;
          flex: 1;

          & > .title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            position: relative;
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;

            & > .course_name {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: calc(100% - 2.5em);
            }

            & > .course_tag {
              margin-left: 0.5em;
              width: 32px;
              height: 15px;
              text-align: center;
              line-height: 15px;
              background: #f0f0f0;
              font-size: 12px;
            }
          }

          & > .desc {
            font-size: 12px;
          }
        }

        & > .course-time {
          flex: 1;
          text-align: center;
          color: #555;

          & > .course-date {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
          }

          & > .date-time {
            font-size: 12px;
          }
        }

        & > .course-status {
          font-size: 14px;
          font-weight: 500;
          color: #29d087;
          flex: 1;
        }
        & > .status_gray {
          color: #cccccc;
        }

        & > .button {
          .button(90px, 36px);
          .button--green;

          & > a {
            color: #fff;
            font-size: 14px;
            text-decoration: none;
          }
        }
        .button-disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    & > .no-course {
      width: 100%;
      height: 150px;
      text-align: center;
      line-height: 150px;
      font-size: 16px;
    }
  }
}
</style>
