<script>
  export default {
    name: 'preClassSchedule',

    created () {
      console.log(this.$route)
      if(process.env.NODE_ENV === 'development'){
        this.url = process.env.VUE_APP_VUE3_PLAN
      }else{
        this.url = window.location.origin + '/app-plan/classSchedule' + window.location.search
      }
    },
    data: () => {
      return{
        url: ''
      }
    }
  }
</script>

<template>
<div class="pre-class-schedule">
  <micro-app name="class-schedule"
             :url="url"
             router-mode="native-scope"
             baseroute="/plan"
             keep-router-state keep-alive iframe ></micro-app>

</div>
</template>

<style scoped lang="less">
  .pre-class-schedule{
    width: 100vw;
    height: 100vh;
  }
</style>
