import * as Constant from "@/views/live/constants/constant";

let tic = null; // tic 实例
let roomID = ""; // 房间号

let liveMode = Constant.LIVE_MODE.private;

let isShowNotificationBox = false; // 通知面板
let msgs = []; // 消息列表
let userList = []; // 用户列表
let groupInfo = {}; // 聊天室信息
let classStatus = 0; // 是否是上课状态 0 未上课  1上课中 2 已下课
let micStatus = 2; // 1 可用状态  2 关闭状态  3 不用状态（浏览器不支持）
let cameraStatus = 0; // 1 开启状态 0关闭状态
let videoStatus = 0; // 1 开启状态  0关闭状态
let voiceStatus = 1; // 1 开启状态  0关闭状态
let shareStatus = 0; // 1 可用状态  0关闭状态
let videoWrap = "video_wrap";
let handUpList = [];
let connectList = [];
let isShowCourseware = false;
let userId =
  (localStorage.authorization && JSON.parse(localStorage.authorization).uuid) ||
  "";
let userToken = sessionStorage.userSign || "";
let canTeach = true;
let recordTaskId = sessionStorage.recordTaskId || "";
let recordInstance = null;
let canBeginClass = true; // 是否可以上课
let uploadQuality = 0;
let downloadQuality = 0;
let setdownTime = 0; //倒计时秒数
let isShowPackUp = true; //左侧文件是否收起
let remoteStreamVideo = false; //是否有远程视频流
let userJionTim = 0; //用户加入tim 0未加入 1已加入
let cameraDeviceId = null;
let speakerDeviceId = null;
let micDeviceId = null;
let isClassTime = ''
export default {
  tic: tic,
  roomID: roomID,
  liveMode: liveMode,
  msgs: msgs,
  userList: userList,
  groupInfo: groupInfo,
  isShowNotificationBox: isShowNotificationBox,
  classStatus: classStatus,
  micStatus: micStatus,
  videoStatus: videoStatus,
  voiceStatus: voiceStatus,
  shareStatus: shareStatus,
  videoWrap: videoWrap,
  cameraStatus: cameraStatus,
  handUpList: handUpList,
  connectList: connectList,
  isShowCourseware: isShowCourseware,
  userId: userId,
  userToken: userToken,
  canTeach: canTeach,
  recordTaskId: recordTaskId,
  recordInstance: recordInstance,
  canBeginClass: canBeginClass,
  uploadQuality: uploadQuality,
  downloadQuality: downloadQuality,
  setdownTime: setdownTime,
  isShowPackUp: isShowPackUp,
  remoteStreamVideo: remoteStreamVideo,
  userJionTim: userJionTim,
  cameraDeviceId: cameraDeviceId,
  speakerDeviceId: speakerDeviceId,
  micDeviceId: micDeviceId,
  isClassTime: isClassTime
};
