<template>
        <el-input
            v-bind="$attrs"
            v-model="dataValue"
            @input="setValue"
        ></el-input>
</template>

<script>
import { formItemMixin } from '@/components/PapaForm/mixins/formItemMixin'
export default {
    mixins: [formItemMixin],
    name: 'FormInput',
    inheritAttrs:false,

    methods: {
    },
}
</script>

<style scoped>

</style>
