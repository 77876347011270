<template>
  <el-dialog
    :title="title"
    :visible.sync="visibility"
    :show-close="showClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :destroy-on-close="true"
    width="50%"
    @close="close"
  >
    <slot name="dialog"></slot>
  </el-dialog>
</template>

<script>
export default {
  name: "PapaDialog",
  props: {
    title: {
      type: String,
      default: "",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visibility: false,
    };
  },
  mounted() {
    this.visibility = this.dialogVisible;
  },
  methods: {
    close() {
      this.$emit("dialogClose");
    },
  },
  watch: {
    dialogVisible(newVal) {
      this.visibility = newVal;
    },
  },
};
</script>

<style scoped></style>
