<template>
  <div class="sidebarwrapper" >
    <div class="sidebar__item">
      <template
          v-if="hasOnlyShowChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
        <app-link v-if="onlyOneChild.meta && !item.hidden" :to="resolvePath(onlyOneChild.path)">
          <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
            <i class="iconfont menu_icon" :class="'papa-icon-'+onlyOneChild.meta.icon||item.meta&&item.meta.icon"></i>
              <span>{{item.hidden}}</span>
            <span>{{generateTitle(onlyOneChild.meta.title)}}</span>
          </el-menu-item>
        </app-link>
      </template>

      <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
        <template slot="title">
          <div v-if="item.meta">
            <i class="iconfont menu_icon" :class="'papa-icon-'+(item.meta && item.meta.icon)"></i>
            <span>{{generateTitle(item.meta.title)}}</span>
          </div>
        </template>
        <sidebar-item
            v-for="child in item.children"
            :key="child.path"
            :is-nest="true"
            :item="child"
            :base-path="resolvePath(child.path)"
            class="nest-menu"
        />
      </el-submenu>
    </div>
  </div>
</template>

<script>
  import path from 'path'

  import { isExternal } from '@/utils/validate'
  import { generateTitle } from '@/utils/i18n'

  import appLink from '@/layout/components/sidebar/link.vue'

  export default {
    name: 'sidebar-item',
    data () {
      return {
          roles: [],
        onlyOneChild: {}
      }
    },
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
      isNest: {
        type: Boolean,
        default: false
      },
      basePath: {
        type: String,
        default: ''
      }
    },
      mounted () {
        this.roles = localStorage.userInfo&&JSON.parse(localStorage.userInfo).roles
      },
      methods: {
      hasOnlyShowChild (children = [], parent) {
          const showingChildren = children.filter(item => {
          if (item.hidden) {
            return false
          } else {
            this.onlyOneChild = item
            return true
          }
        })

        if (showingChildren.length === 1) {
          return true
        }

        // Show parent if there are no child router to display
        if (showingChildren.length === 0) {
          this.onlyOneChild = {...parent, path: '', noShowingChildren: true}
          return true
        }
        return false
      },
      resolvePath (routePath) {
        let pp = routePath
        if (isExternal(pp)) {
          return pp
        }
        if (isExternal(this.basePath)) {
          return this.basePath
        }
        return path.resolve(this.basePath, pp)
      },
      generateTitle
    },
    components: {
      appLink
    }

  }
</script>

<style lang="less" scoped>
  .menu_icon {
    margin-right: 15px;
  }
</style>
