<template>
    <div class="navbar">
        <div class="collapse">
            <!--      <div :class="{'is-active': isActive}" class="collapse-left el-icon-s-fold" @click="toggleClick"></div>-->
        </div>
        <div class="avatar">
            <el-dropdown class="avatar_container right-menu-item hover-effect" trigger="hover">
                <div class="avatar_wrapper">
                    <img v-if="userInfo.avatar" class="avatar_img" :src=userInfo.avatar alt="">
                    <img v-else class="avatar_img" src="../../../assets/img/avatar.png" alt="">
                    <span class="teacher_name">{{ userInfo.username }}</span>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!--                <router-link to="/login">-->
                    <div @click="changePassword">
                        <el-dropdown-item>{{ generateButton('resetPassword') }}</el-dropdown-item>
                    </div>
                    <div @click="loginout">
                        <el-dropdown-item>{{ generateButton('logout') }}</el-dropdown-item>
                    </div>
                    <!--                </router-link>-->
                    <!--                <router-link to="/login">-->
                    <!--                  <el-dropdown-item>个人中心</el-dropdown-item>-->
                    <!--                </router-link>-->
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { generateButton } from '@/utils/i18n'

export default {
    name: 'navbar',
    data () {
        return {
            isCollapse: false,
        }
    },
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo
        })
    },
    methods: {
        toggleClick () {
            this.$emit('toggleClick')
        },
        generateButton,
        loginout () {
            sessionStorage.clear()
            localStorage.clear()
            this.setAuthorization({})
            this.$router.push({
                path: '/login'
            })
        },
        changePassword () {
            this.setPasswordDialog(true)
        },
        ...mapActions([
            'setPasswordDialog',
            'setAuthorization'
        ])
    }
}
</script>

<style lang="less" scoped>
.navbar {
    height: 60px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 46px 0 15px;
}

.avatar_wrapper {
    display: flex;
    height: 100%;
    align-items: center;
}

.avatar_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.is-active {
    transform: rotate(180deg);
}

.teacher_name {
    color: #666;
    margin-left: 8px;
    object-fit: cover;
}
</style>
