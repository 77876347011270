import Fetch from '@/utils/fetch'
import * as Constant from '@/views/live/constants/constant'

// 日历接口

// 班课
const getCalendarCourse = function ( data, fn, errofn ) {
  return new Fetch(`course_schedules/calendar`, 'get', data, fn, errofn)
}

// 教师档期列表
const getTeacherAuction = function ( id, type, data, fn, errofn ) {
  if (id) {
    return new Fetch(
        `teacher_schedules/${id}`,
        type,
        data,
        fn,
        errofn,
        undefined,
        'v2'
    )
  } else {
    return new Fetch(
        `teacher_schedules`,
        type,
        data,
        fn,
        errofn,
        undefined,
        'v2'
    )
  }
}

// 1v1
const getPrivateCalendarCourse = function ( data, fn, errofn ) {
  return new Fetch(`course_plan_tasks/calendar`, 'get', data, fn, errofn)
}

// 班课直播二级列表
const getScheduleCourse = function ( data, fn, errofn ) {
  return new Fetch(`course_schedules`, 'get', data, fn, errofn)
}

//章节列表
const getChapters = function ( id, fn, errofn ) {
  return new Fetch(`courses/${id}/chapters`, 'get', '', fn, errofn)
}
//小节详情
const scheduleDetail = function ( id, fn, errofn ) {
  return new Fetch(`course_schedules/${id}/live`, 'get', '', fn, errofn)
}

// 我的课程 班课
const getClassCourse = function ( data, fn, errofn ) {
  return new Fetch(`courses`, 'get', data, fn, errofn)
}

/*
 * 我的课程 1v1
 * params status 1 未开始  2 已结束
 * **/

const getPrivateCourse = function ( data, fn, errofn ) {
  return new Fetch(`course_plan_details`, 'get', data, fn, errofn)
}

const getPrivateCourseDetail = function ( data, fn, errofn ) {
  return new Fetch(
      `course_plan_details/${data}/course_plan_tasks`,
      'get',
      '',
      fn,
      errofn
  )
}

// 回看接口
const replayCourseDetail = function ( data, id, liveMode, entrance, fn, errofn ) {
  console.log(entrance)
  if (liveMode === Constant.LIVE_MODE.class) {
    if (entrance == Constant.ENTERANCE.speaking) {
      return new Fetch(
          `training/speaking_appointments/${id}/playback`,
          'get',
          data,
          fn,
          errofn
      )
    } else {
      return new Fetch(
          `course_schedules/${id}/playback`,
          'get',
          data,
          fn,
          errofn
      )
    }
  } else {
    return new Fetch(
        `course_plan_tasks/${id}/playback`,
        'get',
        data,
        fn,
        errofn
    )
  }
}

// 聊天记录
const getMsg = function ( url, data, fn, errofn ) {
  return new Fetch(``, 'get', data, fn, errofn)
}
const configLive = function ( data, fn, errofn ) {
  return new Fetch(`config/live`, 'get', data, fn, errofn)
}

// 教师安排
const getTeacherArrange = function ( id, type, data, fn, errofn ) {
  if (id) {
    return new Fetch(
        `teacher_schedules/${id}`,
        type,
        data,
        fn,
        errofn,
        undefined,
        'v2'
    )
  } else {
    return new Fetch(
        `teacher_schedules`,
        type,
        data,
        fn,
        errofn,
        undefined,
        'v2'
    )
  }
}

const getTeacherMakeUp = function ( data, fn, errofn ) {
  return new Fetch(
      `teacher_makeup_schedules`,
      'get',
      data,
      fn,
      errofn,
      undefined,
      'v1'
  )
}

const getTeacherTrial = function ( data, fn, errofn ) {
  return new Fetch(
      `teacher_makeup/calendar`,
      'get',
      data,
      fn,
      errofn,
      undefined,
      'v1'
  )
}


export {
  getCalendarCourse,
  getTeacherAuction,
  getScheduleCourse,
  getPrivateCourse,
  getPrivateCourseDetail,
  getClassCourse,
  getPrivateCalendarCourse,
  replayCourseDetail,
  getMsg,
  getChapters,
  scheduleDetail,
  getTeacherArrange,
  configLive,
  getTeacherMakeUp,
  getTeacherTrial
}
