<template>
  <div class="sidebar">
    <div class="logo">
      <img class="logo__img" src="" alt="" />
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :default-openeds="defaultOpened"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="(route, index) in $router.options.routes"
          :index="index"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import * as variables from "@/assets/config/style.js";

import SidebarItem from "./sidebar-item.vue";
import { mapState } from "vuex";

export default {
  name: "index",
  data() {
    return {
      variables,
      activeMenu: ""
    };
  },
  computed: {
    defaultOpened() {
      return [this.$router.options.routes[3].path];
    },
    ...mapState({
      isCollapse: state => state.globalConfig.isCollapse
    })
  },
  mounted() {
    console.log(53, this.$router.options);
  },
  components: {
    SidebarItem
  }
};
</script>

<style lang="less" scoped>
.sidebar {
  transition: width 0.28s;
  width: 200px !important;
  background-color: #545c64;
  height: 100%;
  position: fixed;
  font-size: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
}

/deep/ .el-menu {
  border: none;
}
</style>
