import Fetch from '@/utils/fetch'

const login = function (data, fn, errorFn, type) {
    console.log(data)
    if (type === 'assistant') {
        return new Fetch('login', 'post', data, fn, errorFn, type)
    } else {
        return new Fetch('login', 'post', data, fn, errorFn, type)
    }
}

//
const getProfile = function (fn, errorFn, type) {
    if (type === 'assistant') {
        return new Fetch('me', 'get', '', fn, errorFn, type)
    } else {
        return new Fetch('me', 'get', '', fn, errorFn, type)
    }
}

// 获取IM token
const getIMToken = function (fn, errofn, type) {
    if (type === 'assistant') {
        return new Fetch('im/user_sign', 'get', '', fn, errofn, type)
    } else {
        return new Fetch('im/user_sign', 'get', '', fn, errofn, type)
    }
}

const changePassword = function (data, fn, errofn,) {
    return new Fetch('password/reset', 'post', data, fn, errofn)
}

const tencentConfigApi = function(data, fn, errofn,){
    return new Fetch('tencent_cloud/captcha/config', 'get', data, fn, errofn)
}


export {
    login,
    getIMToken,
    getProfile,
    changePassword,
    tencentConfigApi
}
