export default {
  route: {
    index: "首页",
    courseCenter: "课程中心",
    mySchedule: "我的课表",
    monthSchedule: "整月安排",
    teacherArrange: "教师安排",
    myCourse: "我的课程",
    courseRecord: "课程记录",
    firstFeedback: "首课管理",
    private: "1v1",
    class: "班课",
    detail: "详情",
    trainingSpeaking: "口语练习",
    appointments: "课程记录",
    plans: "学生列表",
    schedule: "课程安排",
    analytic: "数据统计",
    dashboard: "控制面板",
    mark: "评分统计",
  },
  button: {
    search: "搜索",
    reset: "重置",
    submit: "提交",
    cancel: "取消",
    saveAs: "保存草稿",
    feedback: "填写反馈",
    checkFeedback: "查看反馈",
    batch_modification: "批量修改",
    prev: "上一页",
    next: "下一页",
    firstPage: "首页",
    logout: "退出登录",
    resetPassword: "修改密码",
    intoClassroom: "进入教室",
    checkPlayback: "查看回放",
    checkMark: "查看评价",
    createPlayBack: "生成回放中",
    haveDeleted: "已删除",
  },
  columns: {
    feedback: "反馈",
    passwordOld: "原密码",
    passwordNew: "新密码",
    advice: "建议",
    private: "1v1",
    class: "班课",

    band_score: "评分",
    booked_count: "预约数量",
    basic_information: "基本信息",

    category: "分类",
    created_at: "创建时间",
    criteria: "评分规则",
    class_type: "课种",
    completed_count: "完成数量",
    completed_course: "已完成课节数",
    completed: "已完成",
    cancelled: "已取消",

    date: "日期",
    startDate: "开始时间",
    endDate: "结束时间",
    errorCorrection: "错误指正",
    end_moment: "结束时间",

    is_active: "是否激活",
    is_open: "是否开启",
    independent: "独立",

    multiple: "综合",

    name: "英文名",
    username: "用户名",
    night_shift_hours: "晚班档期开放时长",
    next_exam_date: "下次考试日期",

    operation: "操作",

    placeholderSelect: "请选择",
    placeholderInput: "请输入",
    previous_records: "往期课程记录",

    remaining_count: "总剩余数量",
    reserved: "已预约",

    started_at: "上课时间",
    status: "状态",
    sub_status: "子状态",
    score: "分数",
    schedule: "计划排期",
    start_moment: "开始时间",
    student_absent: "学生缺席课节数",
    student_name: "学生姓名",

    time: "上课时间",
    type: "类型",
    teacher_absent: "老师缺席课节数",

    working_hours: "总档期开放时长",
    wechat: "微信号",
    welcome: "欢迎登录教师系统",
    yes: "是",
    no: "否",
    ielts: "雅思",
    toefl: "托福",
    pragmatic: "实用英语",
    appraise: "评价",

    mark: "评分统计",
    number: "分数",
    ava_score: "平均分",
    point: "分",
    camera: "摄像头",
    microphone: "麦克风",
    peaker: "扬声器",
    start_etection: "开始检测",
    detection_success: "完成检测",
    res_detection: "重新检测",
    detection: "检测中",
    device_test: "设备检测",
    input_volume: "输入音量",
    input_level: "输入电平",
    output_volume: "输出音量",
    audio_play: "音频播放",
    can_see: "看得到",
    can_not_see: "看不到",
    setting: "设置",
    start_class: "上课",
    end_class: "下课",
    audio: "音频",
    screen_share: "屏幕分享",
    stop_share: "退出分享",
    switch_mode: "切换模式",
    whiteboard_mode: "白板模式",
    video_mode: "视频模式",
    fill_feedback: "填写反馈",
    exit_classroom: "退出教室",
    add_time: "添加计时",
    send: "发送",
    me: "我",
    online: "在线",
    offline: "  离线",
    chat: "聊天",
    currently_online: "当前在线",
    speakers_list: "发言列表",
    select_audio: "选择音频",
    manage: "管理",
    selected: "已选",
    complete: "完成",
    delete: "删除",
    close: "关闭",
    add_audio: "添加音频",
    video_window: "视频窗口",
    in_class: "上课中",
    up: "上行",
    down: "下行",
    countdown: "倒计时",
    minute: "分钟",
    second: "秒",
    affirm: "确认",
    select_courseware: "选择课件",
    courseware_manage: "课件管理",
    cancel: "取消",
    modify_success: "修改成功",
    total: "共",
    section: "节",
    course_arrangement: "课程安排",
    finished_section: "已完成课节",
    remaining_section: "剩余课节",
    next_class_time: "下次上课时间",
    view_course: "查看课程",
    no_course: "还没有该分类课程~",
    course_canceled: "课程取消",
    live_ended: "直播已结束",
    replay_generating: "回放生成中",
    to_be_class: "待上课",
    watch_replay: "观看回放",
    intoClassroom: "进入教室",
    hint: "提示",
    notice_board: "公告栏",
    confirm_publish: "确认发布",
    course_living: "课程正在直播中",
    page: "页码",
    current_online: "当前在线",
    admin: "管理员",
    no_one_speak: "还没有人发言",
    remove_speak: "解除禁言",
    all_ban_speak: "全员禁言",
    invite_speak: "抱上麦",
    hang_up: "挂断",
    no_one_hand_up: "还没有人举手哦",
    teacher: "教师",
    assistant: "助教",
    impression: "学员印象",
    open_new_window: "教师将在新窗口打开",
    input_username: "请输入用户名",
    input_password: "请输入密码",
    password_format_error: "密码格式不正确",
    logining: "登录中...",
    evaluate: "评价",
    people_evaluate: "人评价",
    papa_live_teacher: "PAPA直播-教师端",
    papa_live_help_teacher: "PAPA直播-助教端",
    year: "年",
    month: "月",
    unfold: "展开",
    pack_up: "收起",
    day: "日",
    click_here: "点击这里",
    open: "打开",
    sound: "声音",
    startEndTime: '时间',
    remark: '备注',
    Please_Enter_Content: '请输入内容',
    Add_Teacher_Schedule: '添加不可上课时间',
    Delete_Teacher_Schedules: '删除不可上课时间',
    show_courseware:'显示课件',
    hide_courseware:'隐藏课件'
  },
  tips: {
    required: " 不能为空",
    courseStatus: "请选择课程状态",
    serverError: "服务器异常，请联系小总管",
    authorizationExpired: "登录信息过期 请重新登录",
    pageNotFound: "您访问的页面不存在",
    noPermission: "您没有访问该模块的权限，如有疑问请联系小总管",
    allow_browser: "请允许浏览器及网页访问",
    device_connecting: "设备正在连接中，请稍后",
    device_connected: "设备连接成功，请开始检测设备",
    can_see_tips: "是否可以清楚的看到自己?",
    can_see_volume_tips: "是否可以看到音量图标跳动?",
    can_hear_tips: "是否可以听到声音？",
    allow_camera: "您必须授权趴趴教育访问摄像头，才能预览",
    microphone_tips: "麦克风出问题了嘛？调节输入音量，随便说点什么搞笑的话试试",
    speaker_tips: "请调节输出音量，点击播放下方的音频试试",
    load_more: "点击加载更多~",
    upload_local: "从本地上传",
    upload_tips: "只能上传mp3格式的文件，且不超过50M",
    upload_courseware_tips: "只能上传 doc/pdf/ppt格式的文件，且不超过10M",
    open_courseware: "打开课件",
    select_courseware_tips: "请选择课件后添加",
    last_page: "已经是最后一页了~",
    first_page: "已经是第一页了~",
    start_record: "开始录制",
    end_live: "是否结束直播?",
    empty_msg: "不能发送空消息",
    correct_time: "请填写正确的时间",
    not_class: "当前不是上课状态，请上课后开启倒计时",
    not_class_time: "当前不是上课状态，请上课后开启屏幕共享",
    audio_connect_error: "学生端音频连接异常，请与学生共同退出课堂后重新上课",
    local_video_error: "本地视频发送异常，请退出课堂后重新上课",
    video_connect_error: " 您选择的视频输入设备异常，请切换输入设备重新尝试",
    not_end_class: "还未到下课时间，确认退出教室",
    no_zhihu_live: " 暂无知乎直播间信息~",
    not_support_live: "您的浏览器不支持直播课程 建议下载最新版Chrome浏览器",
    second_to_start: "秒后才可开课",
    modify_group_info_success: "修改群资料成功",
    say_to_students: "对同学们说几句吧",
    room_id_empty: "发送群消息时，房间号为空",
    device_connect_success: "设备连接成功，请开始设备检测",
    play_audio_after_class: "上课后才可播放音频",
    file_uploading: "这个文件正在上传或已上传成功",
    file_too_large: "上传文件过大",
    file_format_error: "上传文件的格式不正确",
    file_upload_fail: "上传失败了",
    not_support_screen_share:
      "当前浏览器版本不支持屏幕分享功能，请更换 chrome浏览器或者升级到最新版本~",
    record_generate_fail: "录制生成失败，请联系小总管~",


    no_course_today: "今天没有课程哦~",
    please_start_and_end: '请输入开始时间和结束时间'
  },
  new: {
    mark: "评分统计",
    intoClassroom: "进入教室",
    checkPlayback: "查看回放",
    checkMark: "查看评价",
    createPlayBack: "生成回放中",
    haveDeleted: "已删除",
    ava_score: "平均分",
    number: "人数",
    point: "分",
    camera: "摄像头",
    microphone: "麦克风",
    camera: "扬声器",
    start_etection: "开始检测",
    detection: "检测中",
    detection_success: "检测成功",
    detection_fail: "检测失败",
    res_detection: "重新检测",
    // 输入音量
    input_volume: "输入音量",
    // 输入电平
    input_level: "输入电平",
    // 输出音量
    output_volume: "输出音量",
    // 音频播放
    audio_play: "音频播放",
    //看得到
    can_see: "看得到",
    //看不到
    can_not_see: "看不到",
    //设置
    setting: "设置",
    // 上课
    start_class: "上课",
    // 下课
    end_class: "下课",
    // 音频
    audio: "音频",
    //设备检测
    device_detection: "设备检测",
    // 屏幕分享
    screen_share: "屏幕分享",
    // 停止分享
    stop_share: "停止分享",
    // 切换模式
    switch_mode: "切换模式",
    //白板模式
    whiteboard_mode: "白板模式",
    //视频模式
    video_mode: "视频模式",
    //填写反馈
    fill_feedback: "填写反馈",
    // 退出教室
    exit_classroom: "退出教室",
    //当前不是上课状态，请上课后开启倒计时
    not_class: "当前不是上课状态，请上课后开启倒计时",
    //发送
    send: "发送",
    // 我
    me: "我",
    //在线
    online: "在线",
    // 离线
    offline: "离线",
    //选择音频
    select_audio: "选择音频",
    // 完成
    complete: "完成",
    // 已选
    selected: "已选",
    //删除
    delete: "删除",
    // 从本地上传
    upload_local: "从本地上传",
    //只能上传mp3格式的文件，且不超过50M
    upload_tips: "只能上传mp3格式的文件，且不超过50M",
    //请允许浏览器及网页访问
    allow_browser: "请允许浏览器及网页访问",
    //设备正在连接中，请稍后
    device_connecting: "设备正在连接中，请稍后",
    //您必须授权趴趴教育访问摄像头，才能预览
    allow_camera: "您必须授权趴趴教育访问摄像头，才能预览",
    //麦克风出问题了嘛？调节输入音量，随便说点什么搞笑的话试试
    microphone_tips: "麦克风出问题了嘛？调节输入音量，随便说点什么搞笑的话试试",
    //请调节输出音量，点击播放下方的音频试试
    speaker_tips: "请调节输出音量，点击播放下方的音频试试",
    //是否可以清楚的看到自己?
    can_see_tips: "是否可以清楚的看到自己?",
    //是否可以看到音量图标跳动?
    can_see_volume_tips: "是否可以看到音量图标跳动?",
    //是否可以听到声音？
    can_hear_tips: "是否可以听到声音？",
    //点击加载更多
    load_more: "点击加载更多",
    //选择课件
    select_courseware: "选择课件",
    //管理
    manage: "管理",
    // 课件管理
    courseware_manage: "课件管理",
    //只能上传 doc/pdf/ppt格式的文件，且不超过10M
    upload_courseware_tips: "只能上传 doc/pdf/ppt格式的文件，且不超过10M",
    //打开课件
    open_courseware: "打开课件",
    //请选择课件后添加
    select_courseware_tips: "请选择课件后添加",
    //关闭
    close: "关闭",
    //视频窗口
    video_window: "视频窗口",
    // 已经是最后一页了~
    last_page: "已经是最后一页了~",
    // 已经是第一页了~
    first_page: "已经是第一页了~",
    // 开始录制
    start_record: "开始录制",
    // 是否结束直播
    end_live: "是否结束直播",
    // 已取消
    canceled: "已取消",
    // 上课中
    in_class: "上课中",
    // 上行
    up: "上行",
    // 下行
    down: "下行",
    // 不能发送空消息
    empty_msg: "不能发送空消息",
    // 倒计时
    countdown: "倒计时",
    // 请输入正确的时间
    correct_time: "请输入正确的时间",
    // 分钟
    minute: "分钟",
    // 秒
    second: "秒",
    // 学生端音频连接异常，请与学生共同退出课堂后重新上课
    audio_connect_error: "学生端音频连接异常，请与学生共同退出课堂后重新上课",
    // 您选择的视频输入设备异常，请切换输入设备重新尝试
    video_connect_error: "您选择的视频输入设备异常，请切换输入设备重新尝试",
    // 已经最小了~
    min_size: "已经最小了~",
    // 是否结束直播
    end_live: "是否结束直播",
    // 还未到下课时间，确认退出教室
    not_end_class: "还未到下课时间，确认退出教室",
    //修改成功
    modify_success: "修改成功",
    // 一月
    january: "一月",
    // 二月
    february: "二月",
    // 三月
    march: "三月",
    // 四月
    april: "四月",
    // 五月
    may: "五月",
    // 六月
    june: "六月",
    // 七月
    july: "七月",
    // 八月
    august: "八月",
    // 九月
    september: "九月",
    // 十月
    october: "十月",
    // 十一月
    november: "十一月",
    // 十二月
    december: "十二月",
    //星期一
    monday: "星期一",
    //星期二
    tuesday: "星期二",
    //星期三
    wednesday: "星期三",
    //星期四
    thursday: "星期四",
    //星期五
    friday: "星期五",
    //星期六
    saturday: "星期六",
    //星期日
    sunday: "星期日",
    //共
    total: "共",
    //节
    section: "节",
    //编辑
    edit: "编辑",
    //删除
    delete: "删除",
    // 上次播放至
    last_play: "上次播放至",
    // 恢复播放
    resume_play: "恢复播放",
    //腾讯播放器
    tencent_player: "腾讯播放器",
    //聊天
    chat: "聊天",
    //展开剩余全部聊天
    expand_chat: "展开剩余全部聊天",
    // 选择年
    select_year: "选择年",
    // 年
    year: "年",
    // 选择月
    select_month: "选择月",
    // 月
    month: "月",
    // 未完成
    unfinished: "未完成",
    // 已完成
    finished: "已完成",
    // 已取消
    canceled: "已取消",
    //已结束
    ended: "已结束",
    //课程安排
    course_arrangement: "课程安排",
    //已完成课节
    finished_section: "已完成课节",
    //下次上课时间
    next_class_time: "下次上课时间",
    //查看课程
    view_course: "查看课程",
    //还没有该分类课程
    no_course: "还没有该分类课程",
    // 暂无知乎直播间信息~
    no_zhihu_live: "暂无知乎直播间信息~",
    // 课程取消
    course_canceled: "课程取消",
    // 直播结束
    live_ended: "直播结束",
    // 回放生成中
    replay_generating: "回放生成中",
    // 待上课
    to_be_class: "待上课",
    //观看回放
    watch_replay: "观看回放",
    //进房间
    enter_room: "进房间",
    //老师进入教室
    teacher_enter_room: "老师进入教室",
    //您的浏览器不支持直播课程 建议下载最新版Chrome浏览器
    not_support_live: "您的浏览器不支持直播课程 建议下载最新版Chrome浏览器",
    //秒后才可开课
    second_to_start: "秒后才可开课",
    //修改群资料成功
    modify_group_info_success: "修改群资料成功",
    //公告栏
    notice_board: "公告栏",
    //对同学们说几句吧
    say_to_students: "对同学们说几句吧",
    //确认发布
    confirm_publish: "确认发布",
    // 课程直播中
    course_living: "课程直播中",
    // 页码
    page: "页码",
    // 发送群消息时，房间号为空
    room_id_empty: "发送群消息时，房间号为空",
    // 当前在线
    current_online: "当前在线",
    //发言列表
    speaking_list: "发言列表",
    //管理员
    admin: "管理员",
    //还没有人发言
    no_one_speak: "还没有人发言",
    //解除禁言
    remove_speak: "解除禁言",
    //全员禁言
    all_ban_speak: "全员禁言",
    //抱上麦
    invite_speak: "抱上麦",
    //挂断
    hang_up: "挂断",
    //还没有人举手哦
    no_one_hand_up: "还没有人举手哦",
    //教师
    teacher: "教师",
    //助教
    assistant: "助教",
    //设备连接成功，请开始设备检测
    device_connect_success: "设备连接成功，请开始设备检测",
    // 暂时无法访问摄像头/麦克风，请确保系统授予当前浏览器摄像头/麦克风权限，并且没有其他应用占用摄像头/麦克风
    device_connect_fail:
      "暂时无法访问摄像头/麦克风，请确保系统授予当前浏览器摄像头/麦克风权限，并且没有其他应用占用摄像头/麦克风",
    //用户/系统已拒绝授权访问摄像头或麦克风
    device_connect_refuse: "用户/系统已拒绝授权访问摄像头或麦克风",
    //找不到摄像头或麦克风设备
    device_connect_not_found: "找不到摄像头或麦克风设备",
    // 采集属性设置错误，如果您指定了 cameraId/microphoneId，请确保它们是一个有效的非空字符串
    device_connect_setting_error:
      "采集属性设置错误，如果您指定了 cameraId/microphoneId，请确保它们是一个有效的非空字符串",
    // 初始化本地流时遇到未知错误, 请重试
    device_connect_init_error: "初始化本地流时遇到未知错误, 请重试",
    //请选择课件后添加
    select_courseware: "请选择课件后添加",
    // 上课后才可播放音频
    play_audio_after_class: "上课后才可播放音频",
    // 这个文件正在上传或已上传成功
    file_uploading: "这个文件正在上传或已上传成功",
    // 上传文件过大
    file_too_large: "上传文件过大",
    //上传文件的格式不正确
    file_format_error: "上传文件的格式不正确",
    // 上传失败了
    file_upload_fail: "上传失败了",
    //当前浏览器版本不支持屏幕分享功能，请更换 chrome浏览器或者升级到最新版本~
    not_support_screen_share:
      "当前浏览器版本不支持屏幕分享功能，请更换 chrome浏览器或者升级到最新版本~",
    //本地音频发送异常，请退出课堂后重新上课
    local_audio_error: "本地音频发送异常，请退出课堂后重新上课",
    // /本地视频发送异常，请退出课堂后重新上课
    local_video_error: "本地视频发送异常，请退出课堂后重新上课",
    //您选择的视频输入设备异常，请切换输入设备重新尝试
    video_device_error: "您选择的视频输入设备异常，请切换输入设备重新尝试",
    //录制生成失败，请联系小总管~
    record_generate_fail: "录制生成失败，请联系小总管~",
    //点击这里
    click_here: "点击这里",
    //打开
    open: "打开",
    //声音
    sound: "声音",
    //请输入用户名
    input_username: "请输入用户名",
    //请输入密码
    input_password: "请输入密码",
    //密码格式不正确
    password_format_error: "密码格式不正确",
    //正在登录
    logining: "正在登录",
    //PAPA直播-教师端
    papa_live_teacher: "PAPA直播-教师端",
    //PAPA直播-助教端
    papa_live_help_teacher: "PAPA直播-助教端",
    //学员印象
    impression: "学员印象",
    //评价
    evaluate: "评价",
    //实用英语
    practical_english: "实用英语",
    //商务英语
    business_english: "商务英语",
    //反馈
  },
};
