import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout/layout.vue'
import Dashboard from '@/views/dashboard/index.vue'
import StudentRouter from '@/views/student/router'
import CourseSchedule from '@/views/course/schedule/schedule.vue'
import ExamRouter from '@/views/examRecord/router'
import PreClassSchedule from '@/views/preClassSchedule/preClassSchedule.vue'

let routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/dashboard/index'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    hidden: true
  },
  {
    path: '/dashboard',
    name: 'index',
    hidden: true,
    component: Layout,
    redirect: '/dashboard/index',
    children: [
      {
        path: 'index',
        meta: { title: 'dashboard', icon: 'courseCenter' },
        name: 'dashboardIndex',
        component: Dashboard
      }
    ]
  },
  {
    path: '/plan/classSchedule',
    name: 'preClassSchedule',
    component: PreClassSchedule
  },


]
const personalCourse = [
  {
    path: '/course',
    name: 'course',
    component: Layout,
    redirect: '/course/schedule',
    meta: { title: 'courseCenter', icon: 'course1', role: 'personal-course' },
    children: [
      {
        path: 'schedule',
        component: CourseSchedule,
        meta: { title: 'mySchedule', icon: '', role: 'personal-course' }
      },
      {
        path: 'monthSchedule',
        component: () =>
            import('@/views/course/monthSchedule/monthSchedule_2.vue'),
        meta: { title: 'monthSchedule', icon: '' }
      },
      {
        path: 'teacherArrange',
        component: () =>
            import('@/views/course/teacherMonthSchedule/teacherArrange.vue'),
        meta: { title: 'teacherArrange', icon: '' }
      },
      {
        path: 'myCourse',
        component: () => import('@/views/course/myCourse/myCourse.vue'),
        meta: { title: 'myCourse', icon: '' },
        children: [
          {
            path: 'detail/:id',
            components: {
              default: import('@/views/course/myCourse/myCourse.vue'),
              detail: () =>
                  import('@/views/course/myCourse/courseDetail/courseDetail.vue')
            },
            meta: { title: 'detail' },
            hidden: true
          },
          {
            path: 'private/detail/:id',
            components: {
              default: import('@/views/course/myCourse/myCourse.vue'),
              detail: () =>
                  import('@/views/course/myCourse/courseDetail/courseDetail.vue')
            },
            meta: { title: 'detail' },
            hidden: true
          }
        ]
      },
    ]
  },

  {
    path: '/private/live/:id',
    name: 'privateLive',
    component: () => import('@/views/live/live.vue'),
    hidden: true
  },
  {
    path: '/live/:id',
    name: 'live',
    component: () => import('@/views/live/live.vue'),
    hidden: true
  },
  {
    path: '/course/private/replay/:id',
    component: () => import('@/views/course/courseReplay/courseReplay.vue'),
    hidden: true
  },
  {
    path: '/course/replay/:id',
    component: () => import('@/views/course/courseReplay/courseReplay.vue'),
    hidden: true
  }
]
const coursePlan = [
  {
    path: '/plan',
    name: 'coursePlan',
    component: Layout,
    meta: { title: '课程计划', icon: 'course1', role: 'personal-course' },
    children: [
      {
        path: 'courseList',
        component: () => import('@/views/course/courseList/courseList.vue'),
        name: 'coursePlanList',
        meta: { title: '课程记录', icon: '' },
        isSonApp: true
      },
      {
        path: 'firstCourse',
        component: () => import('@/views/course/firstCourse/firstCourse.vue'),
        name: 'coursePlanFirstCourse',
        meta: { title: '首课管理', icon: '' },
        isSonApp: true
      }
    ]
  },
]

const trainingSpeaking = [
  {
    path: '/trainingSpeaking',
    name: 'training',
    component: Layout,
    redirect: '/trainingSpeaking/appointments',
    meta: {
      title: 'trainingSpeaking',
      icon: 'kouyu_lianxinor',
      role: 'training-speaking'
    },
    children: [
      {
        path: 'schedules',
        meta: { title: 'schedule', icon: '' },
        name: 'trainingSchedules',
        component: () => import('@/views/trainingSpeaking/schedules/index.vue')
      },
      {
        path: 'appointments',
        meta: { title: 'appointments', icon: '' },
        name: 'appointments',
        component: () =>
            import('@/views/trainingSpeaking/appointments/index.vue')
      },
      {
        path: 'detail/:id/:user_id',
        props: true,
        meta: { title: 'detail' },
        name: 'trainPlanDetail',
        hidden: true,
        component: () =>
            import('@/views/trainingSpeaking/plans/detail/detail.vue')
      },

      {
        path: 'analytic',
        meta: { title: 'analytic', icon: '' },
        name: 'trainingAnalytic',
        component: () =>
            import('@/views/trainingSpeaking/analytic/analytic.vue')
      },
      {
        path: 'mark',
        meta: { title: 'mark', icon: '' },
        name: 'mark',
        component: () => import('@/views/trainingSpeaking/mark/mark.vue')
      }
    ]
  },

  {
    path: '/spoken/live/:id',
    name: 'spokenlive',
    component: () => import('@/views/live/live.vue'),
    hidden: true
  },
  {
    path: '/course/spoken/replay/:id',
    component: () => import('@/views/course/courseReplay/courseReplay.vue'),
    hidden: true
  }
]
const roles = localStorage.userInfo && JSON.parse(localStorage.userInfo).roles
if (roles && roles.length !== 0) {
  if (roles.indexOf('personal-course') > -1) {
    routes = routes.concat(personalCourse)
  }
  if (roles.indexOf('training-speaking') > -1) {
    routes = routes.concat(trainingSpeaking)
  }
} else {
  routes = routes.concat(personalCourse)
}
routes = routes.concat(StudentRouter, ExamRouter, coursePlan)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
