<template>
  <div class="welcome">{{ generateColumns("welcome") }}~</div>
</template>

<script>
import { generateColumns } from "@/utils/i18n";
import { configLive } from "@/api/course/course";
import AES from "@/utils/AES";
let Base64 = require("js-base64").Base64;
import * as Config from "@/config/live/live";
export default {
  name: "index",
  mounted() {
    if (!localStorage.authorization) {
      this.$router.replace({
        name: "login",
      });
    }
    this.getAppId();
  },
  methods: {
    generateColumns,
    async getAppId() {
      await configLive(
        {},
        (res) => {
          const { iv, value } = JSON.parse(Base64.decode(res.encrypt));
          const config = JSON.parse(
            AES.decrypt(value, Config.DECODEKEY, "CBC", iv)
          );
          localStorage.setItem(
            "SDKAppId",
            JSON.stringify(config.tencent_cloud.trtc.sdk_app_id)
          );
          localStorage.setItem(
            "SUBAppid",
            JSON.stringify(config.tencent_cloud.vod.sub_app_id)
          );
        },
        () => {
          localStorage.setItem("SDKAppId", JSON.stringify(Config.sdkAppId));
          localStorage.setItem(
            "SUBAppid",
            JSON.stringify(Config.TCPlayerAppId)
          );
        }
      );
    },
  },
};
</script>

<style scoped>
.welcome {
  color: #444;
}
</style>
