let mainClient = null
let webRTC = null
let trtcClient = null
let localStream = null
let isPushing = false
let shareClient = null
let shareStream = null
let remoteVideoStream = null
let volumeValue = 60
let isSupportShare = false
let isVideoCall = false


export default {
    mainClient: mainClient,
  webRTC: webRTC,
  trtcClient: trtcClient,
  localStream: localStream,
  isPushing: isPushing,
  shareClient: shareClient,
  shareStream: shareStream,
  remoteVideoStream: remoteVideoStream,
  volumeValue: volumeValue,
  isSupportShare: isSupportShare,
  isVideoCall: isVideoCall,
  bgAudioStream: null
}
