// sidebar
export const menuText = '#fff'
export const menuActiveText = '#FFD04B'
export const subMenuActiveText = '#bfcbd9'

export const menuBg = '#545C64'
export const menuHover = '#263445'

export const subMenuBg = '#1f2d3d'
export const subMenuHover = '#001528'

export const sideBarWidth = '210px'
