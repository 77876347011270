import * as Types from "./mutationTypes.js";

export default {
  setTeduBoard: ({ commit }, teduBoard) => {
    commit(Types.SET_TEDUBOARD, teduBoard);
  },
  setFileList: ({ commit }, fileList) => {
    commit(Types.SET_FILE_LIST, fileList);
  },
  setFilePagination: ({ commit }, filePagination) => {
    commit(Types.SET_FILE_PAGINATION, filePagination);
  },
  setCurrentFile: ({ commit }, file) => {
    commit(Types.SET_CURRENT_FILE, file);
  },
  setFileBoxStatus: ({ commit }, status) => {
    commit(Types.SET_FILEBOX_STATUS, status);
  },
  setCurrentBoardId: ({ commit }, boardId) => {
    commit(Types.SET_CURRENT_BOARD_ID, boardId);
  },
  setUploadPercent: ({ commit }, percent) => {
    commit(Types.SET_UPLOAD_PERCENT, percent);
  },
  setUploadFile: ({ commit }, file) => {
    commit(Types.SET_UPLOAD_FILE, file);
  },
  setAudioList: ({ commit }, list) => {
    commit(Types.SET_AUDIO_LIST, list);
  },
  setAudioPagination: ({ commit }, pagination) => {
    commit(Types.SET_AUDIO_PAGINATION, pagination);
  },
  setCurrentAudio: ({ commit }, audio) => {
    console.log(audio);
    commit(Types.SET_CURRENT_AUDIO, audio);
  },
  setAudioBarStatus: ({ commit }, status) => {
    commit(Types.SET_IS_SHOW_AUDIO_BAR, status);
  },
  setIsPackUpVideo: ({ commit }, status) => {
    commit(Types.SET_IS_PACK_UP_VIDEO, status);
  },
};
