export const SET_MAIN_CLIENT  = 'set_main_client'
export const SET_WEB_RTC  = 'set_web_rtc'
export const SET_TRTC_CLIENT = 'set_trtc_client'
export const SET_LOCAL_STREAM = 'set_local_stream'
export const SET_IS_PUSHING = 'set_is_pushing'
export const SET_SHARE_CLIENT= 'set_share_client'
export const SET_SHAARE_STREAM = 'set_share_stream'
export const SET_REMOTE_VIDEO_STREAM = 'set_remote_video_stream'
export const SET_VOLUME_VALUE = 'set_volume_value'
export const SET_IS_SUPPORT_SHARE = 'set_is_support_share'
export const SET_IS_VIDEO_CALL = 'set_is_video_call'
export const SET_BG_AUDIO_STREAM = 'set_bg_audio_stream'
