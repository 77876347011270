import * as Types from "./mutationTypes";
import Util from "@/utils/utils";

export default {
  [Types.SET_TIC](state, tic) {
    state.live = tic;
  },
  [Types.SET_ROOM_ID](state, roomID) {
    state.roomID = roomID;
  },
  [Types.SET_LIVE_MODE](state, mode) {
    state.liveMode = mode;
  },

  [Types.ADD_MSG](state, msg) {
    state.msgs.push(msg);
  },
  [Types.SET_USER_LIST](state, list) {
    let listLength = list.length;
    let localListLength = state.userList.length;
    if (listLength > localListLength) {
      list.forEach((ele) => {
        if (Util.findUser(ele, state.userList) === "") {
          state.userList.push(ele);
        }
      });
    } else {
      let newList = [];
      list.forEach((ele) => {
        let index = Util.findUser(ele, state.userList);
        if (index !== "") {
          newList.push(state.userList[index]);
        }
      });
      state.userList = newList;

      let _handList = [];
      state.handUpList.forEach((ele) => {
        let index = Util.findUser(ele, state.userList);
        if (index !== "") {
          _handList.push(ele);
        }
      });
      state.handUpList = _handList;
    }
  },
  [Types.ADD_USER](state, user) {
    state.userList.push(user);
  },
  [Types.DEL_USER](state, user) {
    state.userList.splice(Util.findUser(user, state.userList), 1);
  },
  [Types.SET_GROUP_INFO](state, groupInfo) {
    state.groupInfo = groupInfo;
  },
  [Types.SET_NOTIFICATION_STATUS](state, status) {
    state.isShowNotificationBox = status;
  },
  [Types.SET_USER_HAND_STATUS](state, user) {
    let userIndex = Util.findUser(user, state.userList);
    let handIndex = Util.findUser(user, state.handUpList);
    let oldUser = state.userList[userIndex];
    let newUser = { ...oldUser };
    newUser.handUp = user.handUp;
    console.log(user);
    console.log(newUser, handIndex);

    if (user.handUp) {
      if (handIndex === "") {
        state.handUpList.push(newUser);
      }
    } else {
      state.handUpList.splice(handIndex, 1);
    }
    state.userList.splice(userIndex, 1, newUser);
  },
  [Types.SET_USER_CONNECT_STATUS](state, user) {
    let userIndex = Util.findUser(user, state.userList);
    let connectIndex = Util.findUser(user, state.handUpList);
    let oldUser = state.userList[userIndex];
    let newUser = { ...oldUser };
    newUser.isConnected = user.isConnected;
    if (user.isConnected) {
      state.handUpList.splice(connectIndex, 1, newUser);
    }
    state.userList.splice(userIndex, 1, newUser);
  },
  [Types.SET_CLASS_STATUS](state, status) {
    state.classStatus = status;
  },
  [Types.SET_MIC_STATUS](state, status) {
    state.micStatus = status;
  },
  [Types.SET_VIDEO_STATUS](state, status) {
    state.videoStatus = status;
  },
  [Types.SET_VOICE_STATUS](state, status) {
    state.voiceStatus = status;
  },
  [Types.SET_SHARE_STATUS](state, status) {
    state.shareStatus = status;
  },
  [Types.SET_VIDEO_WRAP](state, wrap) {
    state.videoWrap = wrap;
  },

  [Types.SET_CAMERA_STATUS](state, status) {
    state.cameraStatus = status;
  },
  [Types.SET_USER_MUTE](state, user) {
    let userIndex = Util.findUser(user, state.userList);
    let oldUser = state.userList[userIndex];
    let newUser = { ...oldUser };
    newUser.mute = user.mute;
    state.userList.splice(userIndex, 1, newUser);
  },
  [Types.SET_CONNECT_LIST](state, list) {
    state.connectList = list;
  },
  [Types.SET_HAND_UP_LIST](state, list) {
    state.handUpList = list;
  },

  [Types.SET_IS_SHOW_COURSE_WARE_STATUS](state, status) {
    state.isShowCourseware = status;
  },
  [Types.SET_USER_IM_ID](state, uid) {
    state.userId = uid;
  },
  [Types.SET_USER_IM_TOKEN](state, token) {
    state.userToken = token || sessionStorage.getItem("userSign");
  },
  [Types.SET_CAN_TEACH_STATUS](state, status) {
    state.canTeach = status;
  },
  [Types.CLEAR_MSG_LIST](state) {
    state.msgs = [];
  },
  [Types.CLEAR_USER_LISST](state) {
    state.userList = [];
  },
  [Types.CLEAR_TIC](state) {
    state.msgs = [];
    state.userList = [];
    state.live = null;
    state.groupInfo = {};
    state.handUpList = [];
    state.connectList = [];
    state.teduBoard = null;
  },
  [Types.SET_RECORD_TASK_ID](state, id) {
    console.log(id);
    state.recordTaskId = id;
  },
  [Types.SET_RECORD_INSTANCE](state, record) {
    state.recordInstance = record;
  },
  [Types.ADD_HISTORY_MSG](state, list) {
    state.msgs = [...list, ...state.msgs];
  },
  [Types.CAN_BEGIN_CLASS](state, status) {
    state.canBeginClass = status;
  },
  [Types.SET_UPLOAD_QUALITY](state, quality) {
    state.uploadQuality = quality;
  },
  [Types.SET_DOWNLOAD_QUALITY](state, quality) {
    state.downloadQuality = quality;
  },
  [Types.SET_DOWN_TIME](state, status) {
    state.setdownTime = status;
  },
  [Types.SET_IS_SHOW_PACK_UP](state, status) {
    state.isShowPackUp = status;
  },
  [Types.SET_REMOTE_STREAM_VIDEO](state, status) {
    state.remoteStreamVideo = status;
  },
  [Types.SET_UESR_JION_TIM](state, status) {
    state.userJionTim = status;
  },
  [Types.SET_CAMERA_DEVICE_ID](state, status) {
    state.cameraDeviceId = status;
  },
  [Types.SET_SPEAKER_DEVICE_ID](state, status) {
    state.speakerDeviceId = status;
  },
  [Types.SET_MIC_DEVICE_ID](state, status) {
    state.micDeviceId = status;
  },
  [Types.ISCLASSTIME](state, status) {
    state.isClassTime = status;
  },
};
