import * as Types from './mutationTypes.js'

export default {
  setNextMsgSeq: ( { commit }, seq ) => {
    commit(Types.SET_NEXT_MSG_SEQ, seq)
  },
  setIsCompleted: ( { commit }, status ) => {
    commit(Types.SET_IS_COMPLETED, status)
  },
  setTim: ( { commit }, tim ) => {
    commit(Types.SET_TIM, tim)
  },
  setIsOnline: ( { commit }, status ) => {
    commit(Types.SET_ISONLINE, status)
  },
  setIsOnlineMessageNum: ( { commit }, status ) => {
    commit(Types.SET_ISONLINEMESSAGENUM, status)
  },
  setStudentInClassStatus: ( { commit }, status ) => {
    console.log('setStudentInClassStatus', status)
    commit(Types.SET_STUDENT_IN_CLASS_STATUS, status)
  },
  setIsInClassOnline: ( { commit }, status ) => {
    commit(Types.SET_IS_IN_CLASS_ONLINE, status)
  },
  setStudentPlatform: ( { commit }, platform ) => {
    commit(Types.SET_STUDENT_PLATFORM, platform)
  },
  setCustomOnlineMessageStamp: ( { commit }, stamp ) => {
    commit(Types.SET_CUSTOM_ONLINE_MESSAGE_STAMP, stamp)
  },
  setStudentOnlineStatus: ({commit}, status) =>{
    commit(Types.SET_STUDENT_ONLINE_STATUS, status)
  }

}
