<template>
    <div class="layout">
        <div class="layout_normal" :class="classObj">
            <sidebar></sidebar>
            <div class="main">
                <div class="main__breadcrumb" :class="isBreadcrumb?'breadcrumb-border':''">
                    <bread-crumb></bread-crumb>
                    <nav-bar :is-active="isCollapse" @toggleClick='toggleSidebar'></nav-bar>

                </div>
                <div class="main__container">
                    <main-view></main-view>
                </div>
            </div>
        </div>
        <div class="layout_fullScreen"></div>
    </div>
</template>

<script>
import sidebar from '@/layout/components/sidebar/index.vue'
import MainView from '@/layout/components/mainView/mainView'
import NavBar from '@/layout/components/navbar/navbar.vue'
import BreadCrumb from '@/layout/components/breadcrumb/breadcrumb.vue'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'layout',
    data () {
        return {
            isBreadcrumb: true
        }
    },
    components: {
        MainView,
        sidebar,
        NavBar,
        BreadCrumb
    },
    computed: {
        ...mapState({
            isCollapse: state => state.globalConfig.isCollapse
        }),
        classObj () {
            return {
                hideSidebar: !this.isCollapse,
                openSidebar: this.isCollapse,
                // withoutAnimation: this.sidebar.withoutAnimation,
                mobile: 'mobile'
            }
        }
    },
    methods: {
        toggleSidebar () {
            this.setIsCollapse(!this.isCollapse)
        },
        ...mapActions([
            'setIsCollapse'
        ])
    }
}
</script>

<style lang="less" scoped>
.main {
    margin-left: 200px;
    height: 100%;
}

.main__breadcrumb {
    box-sizing: border-box;
    width: calc(100% - 60px);
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 54px;
    margin: 0 30px;
}

.breadcrumb-border {
    border-bottom: 1px solid #e6e6e6;

}

.main__container {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 30px;
    height: calc(100% - 60px);
}

&.mobile.openSidebar {
    position: fixed;
    top: 0;
}

.hideSidebar .fixed-header {
    width: calc(100% - 54px)
}

.mobile .fixed-header {
    width: 100%;
}

</style>
