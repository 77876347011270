import Layout from '@/layout/layout.vue'
import StudentIndex from '@/views/student/student.vue'


export default  [
  {
    path: "/student",
    name: "student",
    component: Layout,
    redirect: "/student/list",
    meta: { title: "plans", icon: "xuesheng", role: "personal-course" },
    children: [
      {
        path: "/student/list",
        component: ()=> import('@/views/student/student.vue'),
        name: 'StudentIndex',
        meta: { title: "plans", icon: "xuesheng", role: "personal-course" }
      },
      {
        path: "/student/detail/:studentID*",
        props: true,
        component: ()=> import('@/views/student/student.vue'),
        name: 'StudentDetail',
        meta: { title: "学生详情", icon: "xuesheng", role: "personal-course" },
        hidden: true,
      }
    ]
  }
]
