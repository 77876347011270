import { generateColumns, generateButton } from "@/utils/i18n";
import Utils from "@/utils/utils";

export default {
  name: "PapaTable",
  data() {
    return {
      formatMoment: Utils.formatMoment,
      searchParams: {},
      tableHeight: 400,
      copyPage: "",
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => {
        {
        }
      },
    },
    tableColumn: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableConfig: {
      type: Object,
      default: () => {
        return {
          buttons: [],
        };
      },
    },
    pagination: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSearchTable: {
      type: Boolean,
      default: true,
    },
    search: {
      formColumn: {
        type: Array,
        default: () => {
          return [];
        },
      },
      formData: {
        type: Object,
        default: () => {
          {
          }
        },
      },
      config: {
        type: Object,
        default: () => {
          {
          }
        },
      },
    },
  },
  computed: {
    operationWidth() {
      if (this.tableConfig.operationWidth) {
        return this.tableConfig.operationWidth;
      } else {
        let btnNum = 0;
        this.tableConfig.buttons.forEach((button) => {
          if (button.size) {
            if (button.size === "big") {
              btnNum += 1.4;
            } else if (button.size === "super") {
              btnNum += 2;
            }
          } else {
            btnNum++;
          }
        });
        return 80 * btnNum;
      }
    },
    height() {
      return this.tableConfig.height || "calc(100vh - 104px)";
    },
    prevButtonStatus() {
      return this.pagination.current_page < 2;
    },
    nextButtonStatus() {
      return Boolean(!this.pagination.next);
    },
  },
  mounted() {
    console.log(this.$route.path);
    this.getData();
    this.$nextTick(() => {
      this.tableHeight =
        this.tableConfig.maxHeight ||
        (this.$refs.tableConRef && this.$refs.tableConRef.offsetHeight);
    });
  },
  methods: {
    getData(query) {
      this.$emit("getData", query);
    },
    handleEditTable(row) {
      this.$emit("handleEdit", row);
    },
    handleDeleteTable(row) {
      this.$emit("handleDelete", row);
    },
    handleCustomTable(row, event) {
      this.$emit("handleCustom", row, event);
    },
    submitForm() {
      console.log(63);
    },
    searchForm(val) {
      if (
        Object.keys(this.searchParams).length === 0 &&
        Object.keys(val).length === 0
      )
        return;
      this.searchParams = _.cloneDeep(val);
      this.getData(val);
    },
    handleCurrentChange(page) {
      let params = {
        page: page,
        ...this.searchParams,
      };
      this.getData(params);
    },
    readData(key, obj) {
      let keyArr = key.split(".");
      switch (keyArr.length) {
        case 1:
          return obj[key];
        case 2:
          return obj[keyArr[0]][keyArr[1]];
        case 3:
          return obj[keyArr[0]][keyArr[1]][keyArr[2]];
        default:
          return obj[key];
      }
    },
    handleLinkParams(params, data) {
      let obj = {};
      params.forEach((ele) => {
        if (ele.indexOf(".") > -1) {
          let key = ele.replaceAll(".", "_");
          obj[key] = this.readData(ele, data);
        } else {
          obj[ele] = data[ele];
        }
      });
      return obj;
    },
    goFirst() {
      if (this.copyPage === 1) return;
      let page = 1;
      this.copyPage = page;
      this.handleCurrentChange(page);
    },
    prevClick() {
      let current = this.pagination.current_page;
      if (this.copyPage && current !== this.copyPage) return;
      let page = current > 1 ? current - 1 : current;
      this.copyPage = page;
      this.handleCurrentChange(page);
    },
    nextClick() {
      let current = this.pagination.current_page;
      if (this.copyPage && current !== this.copyPage) return;
      let page = current > 0 ? current + 1 : current;
      this.copyPage = page;
      this.handleCurrentChange(page);
    },

    generateColumns,
    generateButton,
  },
};
