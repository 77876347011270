import * as Types from './mutationTypes.js'

export default {
  [ Types.SET_NEXT_MSG_SEQ ] ( state, seq ) {
    state.nextMsgSeq = seq
  },
  [ Types.SET_ISONLINE ] ( state, status ) {
    state.isOnline = status
  },
  [ Types.SET_ISONLINEMESSAGENUM ] ( state, status ) {
    state.isOnlineMessageNum += 1
  },
  [ Types.SET_STUDENT_IN_CLASS_STATUS ] ( state, status ) {
    state.studentInClassStatus = status
  },
  [ Types.SET_IS_IN_CLASS_ONLINE ] ( state, status ) {
    state.isInClassOnline = status
  },
  [ Types.SET_IS_COMPLETED ] ( state, status ) {
    state.isCompleted = status
  },
  [ Types.SET_TIM ] ( state, tim ) {
    state.tim = tim
  },
  [ Types.SET_STUDENT_PLATFORM ] ( state, platform ) {
    state.studentPlatform = platform
  },
  [ Types.SET_CUSTOM_ONLINE_MESSAGE_STAMP ] ( state, stamp ) {
    state.customOnlineMessageStamp = stamp
  },
  [ Types.SET_STUDENT_ONLINE_STATUS ] ( state, status ) {
    state.studentOnlineStatus = status
  }
}
