<template>
    <el-switch v-bind="$attrs"
               v-model="dataValue"
               @change="setValue"
    ></el-switch>
</template>

<script>
import { formItemMixin } from '@/components/PapaForm/mixins/formItemMixin'

export default {
    mixins: [formItemMixin],
    name: 'FormSwitch',
    props: {
        formValue: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style scoped>

</style>
