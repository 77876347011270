let videoMode = 2; //1白板 2视频
let deviceState = {}; //设备状态
let showConnectResult = false; //是否显示连接结果
let testStatus = false; //是否显示连接结果
let beforeShareVideoModeStatus = null //分享前的视频模式
export default {
  videoMode: videoMode,
  deviceState: deviceState,
  showConnectResult: showConnectResult,
  testStatus: testStatus,
  beforeShareVideoModeStatus: beforeShareVideoModeStatus
};
