let nextMsgSeq = 0
let isCompleted = true
let isOnline = false
let isOnlineMessageNum = 0
let studentInClassStatus = false
let isInClassOnline = false

export default {
    tim: null,
  nextMsgSeq: nextMsgSeq,
  isCompleted:isCompleted,
  isOnline:isOnline,
  isOnlineMessageNum: isOnlineMessageNum,
  studentInClassStatus: studentInClassStatus,
  isInClassOnline: isInClassOnline,

  studentPlatform: 'app',
  customOnlineMessageStamp: '',
  studentOnlineStatus: false,
}
